import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  DragEvent,
} from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

const FileDropTextarea: React.FC<FileDropTextareaProps> = ({
  alert,
  setTextContent,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(Boolean(alert));
  const [alertMessage, setAlertMessage] = useState<string>(alert);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleDrop = (e: DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];

    if (file && isValidFileType(file)) {
      handleFile(file);
    } else {
      showAlertMessage(
        "Invalid file type. Please upload a .txt, .json, or .geojson file."
      );
      e.dataTransfer.items.clear();
    }
  };

  const handleDragOver = (e: DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextContent(e.target.value);
  };

  const handleDoubleClick = () => {
    fileInputRef.current?.click();
  };

  const isValidFileType = (file: File) => {
    return ["txt", "json", "geojson"].includes(file.name.split(".")[1]);
  };

  const handleFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        const result = event.target.result as string;
        setTextContent(result);
        if (textareaRef.current) {
          textareaRef.current.value = result;
        }
      }
    };
    reader.onerror = () => {
      showAlertMessage("Error reading file.");
    };
    reader.readAsText(file);
  };

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && isValidFileType(file)) {
      handleFile(file);
    } else {
      showAlertMessage(
        "Invalid file type. Please upload a .txt, .json, or .geojson file."
      );
    }
  };

  const showAlertMessage = (message: string) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <>
      <FloatingLabel
        controlId="floatingTextarea2"
        label="You can Drag and Drop File here"
      >
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          className="font-mono"
          style={{ height: "200px" }}
          ref={textareaRef}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onChange={handleChange}
          onDoubleClick={handleDoubleClick}
        />
      </FloatingLabel>
      {showAlert && (
        <div className="flex items-center justify-between bg-red-100 border border-red-400 text-red-700 mt-2 px-3 py-2 rounded relative text-sm">
          <span>{alertMessage}</span>
          <button
            type="button"
            className="absolute top-1 right-1 p-1"
            onClick={() => setShowAlert(false)}
          >
            <svg
              className="w-4 h-4 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      )}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".txt,.json,.geojson"
        onChange={handleFileInputChange}
      />
    </>
  );
};

export default FileDropTextarea;
