import { useState, useEffect } from "react";
import { Button, Modal, Alert, Badge } from "react-bootstrap";
import { useData } from "@Contexts/DataContext";
import { useAuth } from "@Contexts/AuthContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { db } from "@Config/firebase";
import { Link } from "react-router-dom";
import { updateDoc, doc, arrayUnion } from "firebase/firestore";

const DataMarket = () => {
  const { user } = useAuth();
  const { retails, organizations, categories } = useData();
  const [selectedRetail, setSelectedRetail] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultOrganizationId = user?.data?.default_organization;
  const defaultOrganization = organizations.find(
    (org) => org.id === defaultOrganizationId
  );
  const purchasedRetails = defaultOrganization?.retails || [];
  const member = defaultOrganization?.members?.find(
    (mem: any) => mem.uid === user?.uid
  );

  const handlePurchase = (retail: any) => {
    if (!purchasedRetails.includes(retail.id)) {
      setSelectedRetail(retail);
      setShowConfirmation(true);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (defaultOrganizationId && selectedRetail && organizations) {
        const organization = organizations.find(
          (org) => org.id === defaultOrganizationId
        );
        if (organization) {
          const updatedCredits = organization.credits - selectedRetail.price;
          if (updatedCredits < 0) {
            setInsufficientCredits(true);
            setShowConfirmation(false);
            return;
          }
          const transaction = {
            amount: selectedRetail.price,
            description: `Purchased ${selectedRetail.name}`,
            timestamp: new Date(),
            type: "OUTGOING",
            user: user?.uid,
          };
          await updateDoc(doc(db, `organizations/${organization.id}`), {
            credits: updatedCredits,
            retails: arrayUnion(selectedRetail.id),
            transactions: arrayUnion(transaction),
          });
          setShowConfirmation(false);
        } else {
          console.error("Default organization not found.");
        }
      } else {
        console.error(
          "Default organization ID or selectedRetail not available."
        );
      }
    } catch (error) {
      console.error(
        "Error adding retail ID to organization or updating credits:",
        error
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (insufficientCredits) {
      setTimeout(() => {
        setInsufficientCredits(false);
      }, 3000);
    }
  }, [insufficientCredits]);

  const getCategoryBadge = (categoryId: string) => {
    const category = categories.find((cat) => cat.id === categoryId);
    return category ? (
      <Badge
        bg={category.color}
        style={{
          color: category.color === "light" ? "black" : "white",
          width: "min-content",
          marginLeft: 8,
        }}
      >
        {category.name}
      </Badge>
    ) : (
      <Badge bg="secondary" style={{ marginLeft: 8 }}>
        No Category Selected
      </Badge>
    );
  };

  if (member?.role === "owner") {
    return (
      <>
        {insufficientCredits && (
          <Alert
            variant="danger"
            onClose={() => setInsufficientCredits(false)}
            dismissible
            className="mt-4 fixed top-0 left-1/2 transform -translate-x-1/2 z-50 w-1/3 text-black text-center p-2 border-b border-gray-200 shadow-lg"
          >
            Insufficient credits,
            <Link
              to={`/manager/organizations/${defaultOrganizationId}`}
              className="btn-xs py-1 px-2 rounded text-black"
            >
              Purchase Credits
            </Link>
          </Alert>
        )}

        <div className="pl-64 mt-4 mx-4">
          <div className="flex justify-between items-center">
            <h2 className="font-bold text-cprimary">The Data Market</h2>
          </div>
          <hr style={{ border: "1px rgb(255 29 127) solid" }} />
          <div className="grid grid-cols-3 gap-2 mt-4">
            {retails.map((retail: any, index: number) => (
              <div key={index} className="rounded-lg p-4 border h-full">
                <div className="flex flex-col h-full">
                  <div className="flex items-center mb-2">
                    {retail.pin && (
                      <img
                        src={retail.pin}
                        alt={retail.name}
                        className="w-8 h-8 mr-2 mb-2"
                      />
                    )}
                    <h3 className="text-xl font-semibold">{retail.name}</h3>
                  </div>
                  <div className="flex-grow">
                    <span>{retail.description}</span>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div className="flex items-center">
                      <span>
                        <FaMapMarkerAlt className="inline mr-2" />
                        {retail.locations}
                      </span>
                      <span className="mr-1">
                        {getCategoryBadge(retail.category)}
                      </span>
                    </div>
                    <span className="font-semibold">
                      {retail.price} Credits
                    </span>
                  </div>
                  <div className="mt-3 text-center">
                    <Button
                      className="w-full"
                      id="theme-button"
                      onClick={() => handlePurchase(retail)}
                      disabled={purchasedRetails.includes(retail.id)}
                    >
                      {purchasedRetails.includes(retail.id)
                        ? "Purchased"
                        : "Purchase"}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Modal
            show={showConfirmation}
            onHide={() => setShowConfirmation(false)}
            backdrop={loading ? "static" : true}
            keyboard={!loading}
          >
            <Modal.Header closeButton>
              <Modal.Title
                style={{
                  color: "rgb(255 29 127)",
                  fontWeight: "bold",
                  fontSize: 24,
                }}
              >
                Confirm Purchase
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex flex-col justify-between">
              <p className="text-lg font-bold">{selectedRetail?.name}</p>
              <p className="text-sm  flex justify-between">
                <span className="font-semibold ">
                  <FaMapMarkerAlt className="inline mr-1 " />
                  {selectedRetail?.locations}
                </span>
                <span className="ml-[5px] font-semibold">
                  {" "}
                  {selectedRetail?.price} Credits
                </span>
              </p>
              <div className="flex justify-end gap-[10px]">
                <Button
                  onClick={() => setShowConfirmation(false)}
                  className="btn-xs py-1 px-2 rounded text-black bg-transparent border-0"
                >
                  Close
                </Button>
                <Button id="theme-button" disabled={loading} onClick={onSubmit}>
                  Confirm
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  } else {
    return (
      <div className="pl-64 mt-4 mx-4">
        <div className="flex justify-between items-center">
          <h2 className="font-bold text-cprimary">Data Market</h2>
        </div>
        <hr style={{ border: "1px rgb(255 29 127) solid" }} />
        <div className="text-center">
          <p className="text-lg font-bold">
            You do not have permission to access this page.
          </p>
        </div>
      </div>
    );
  }
};

export default DataMarket;
