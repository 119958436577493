import React, { useState } from "react";
import { AiOutlinePlus, AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { db } from "@Config/firebase";
import { useData } from "@Contexts/DataContext";
import { GoChevronUp, GoChevronDown } from "react-icons/go";

import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Table, Button, Modal, FloatingLabel, Form } from "react-bootstrap";

const Templates: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const [stages, setStages] = useState<Stage[]>([]);
  const [expandedStages, setExpandedStages] = useState<number[]>([]);
  const [expandedTasks, setExpandedTasks] = useState<Record<number, number[]>>(
    {}
  );
  const [templateName, setTemplateName] = useState("");
  const [templateNameError, setTemplateNameError] = useState("");
  const [editingTemplateId, setEditingTemplateId] = useState<string | null>(
    null
  );
  const { templates } = useData();

  const openModal = (template?: Template) => {
    if (template) {
      const stages = Object.keys(template.pipeline).map((stageName) => {
        const stage = template.pipeline[stageName];

        // Check if stage is boolean or an object
        const isStageBoolean = typeof stage === "boolean";

        const tasks = !isStageBoolean
          ? Object.keys(stage).map((taskName) => {
              const task = stage[taskName];
              const subtasks =
                typeof task === "boolean"
                  ? []
                  : Object.keys(task).map((subtaskName) => ({
                      name: subtaskName,
                      completed: task[subtaskName],
                      selected: task[subtaskName],
                    }));

              return {
                name: taskName,
                subtasks,
                selected:
                  typeof task === "boolean"
                    ? task
                    : Object.values(task).every((subtask) => subtask),
              };
            })
          : []; // If stage is boolean, set tasks to empty array

        return {
          name: stageName,
          tasks,
          selected: isStageBoolean
            ? stage
            : tasks.every((task) => task.selected),
        };
      });

      setStages(stages);
      setTemplateName(template.name);
      setEditingTemplateId(template.id);
    } else {
      setStages([]);
      setTemplateName("");
      setEditingTemplateId(null);
    }
    setExpandedStages([]);
    setExpandedTasks({});
    setTemplateNameError("");
    setModalOpen(true);
  };

  const addTask = (stageIndex: number) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks.push({
      name: "",
      subtasks: [],
      selected: false,
    });
    setStages(newStages);
  };

  const addSubtask = (stageIndex: number, taskIndex: number) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks.push({
      name: "",
      completed: false,
      selected: false,
    });
    setStages(newStages);
  };

  const handleStageNameChange = (stageIndex: number, value: string) => {
    const newStages = [...stages];
    newStages[stageIndex].name = value;
    setStages(newStages);
  };

  const handleTaskNameChange = (
    stageIndex: number,
    taskIndex: number,
    value: string
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].name = value;
    setStages(newStages);
  };

  const handleSubtaskNameChange = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number,
    value: string
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex].name = value;
    setStages(newStages);
  };

  const handleStageSelectionChange = (stageIndex: number) => {
    const newStages = [...stages];
    const selected = !newStages[stageIndex].selected;
    newStages[stageIndex].selected = selected;
    newStages[stageIndex].tasks.forEach((task) => {
      task.selected = selected;
      task.subtasks.forEach((subtask) => {
        subtask.selected = selected;
      });
    });
    setStages(newStages);
  };

  const handleTaskSelectionChange = (stageIndex: number, taskIndex: number) => {
    const newStages = [...stages];
    const selected = !newStages[stageIndex].tasks[taskIndex].selected;
    newStages[stageIndex].tasks[taskIndex].selected = selected;
    newStages[stageIndex].tasks[taskIndex].subtasks.forEach((subtask) => {
      subtask.selected = selected;
    });
    setStages(newStages);
  };

  const handleSubtaskSelectionChange = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex].selected =
      !newStages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex].selected;
    setStages(newStages);
  };

  const deleteStage = (stageIndex: number) =>
    setStages(stages.filter((_, index) => index !== stageIndex));

  const deleteTask = (stageIndex: number, taskIndex: number) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks = newStages[stageIndex].tasks.filter(
      (_, index) => index !== taskIndex
    );
    setStages(newStages);
  };

  const deleteSubtask = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks = newStages[
      stageIndex
    ].tasks[taskIndex].subtasks.filter((_, index) => index !== subtaskIndex);
    setStages(newStages);
  };

  const saveJson = async () => {
    if (templateName.trim() === "") {
      setTemplateNameError("Template Name is required.");
      return;
    }

    const jsonOutput: JsonOutput = stages.reduce((acc, stage, stageIndex) => {
      const stageName = stage.name || `Stage ${stageIndex + 1}`;
      if (stage.tasks.length === 0) {
        acc[stageName] = stage.selected;
      } else {
        acc[stageName] = stage.tasks.reduce((taskAcc, task, taskIndex) => {
          const taskName = task.name || `Task ${taskIndex + 1}`;
          if (task.subtasks.length === 0) {
            taskAcc[taskName] = task.selected;
          } else {
            taskAcc[taskName] = task.subtasks.reduce(
              (subtaskAcc, subtask, subtaskIndex) => {
                const subtaskName =
                  subtask.name || `Subtask ${subtaskIndex + 1}`;
                subtaskAcc[subtaskName] = subtask.selected;
                return subtaskAcc;
              },
              {} as { [subtaskName: string]: boolean }
            );
          }
          return taskAcc;
        }, {} as { [taskName: string]: boolean | { [subtaskName: string]: boolean } });
      }
      return acc;
    }, {} as JsonOutput);

    try {
      if (editingTemplateId) {
        const templateRef = doc(db, "templates", editingTemplateId);
        await updateDoc(templateRef, {
          name: templateName,
          pipeline: jsonOutput,
          timestamp: new Date(),
        });
        console.log("Document updated with ID: ", editingTemplateId);
      } else {
        const docRef = await addDoc(collection(db, "templates"), {
          name: templateName,
          pipeline: jsonOutput,
          timestamp: new Date(),
        });
        console.log("Document written with ID: ", docRef.id);
      }
      setModalOpen(false);
    } catch (e) {
      console.error("Error saving document: ", e);
    }
  };

  const deleteTemplate = async (templateId: string) => {
    try {
      await deleteDoc(doc(db, "templates", templateId));
      console.log("Document deleted with ID: ", templateId);
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  const toggleStageExpand = (stageIndex: number) => {
    setExpandedStages((prev) =>
      prev.includes(stageIndex)
        ? prev.filter((index) => index !== stageIndex)
        : [...prev, stageIndex]
    );
  };

  const toggleTaskExpand = (stageIndex: number, taskIndex: number) => {
    setExpandedTasks((prev) => ({
      ...prev,
      [stageIndex]: prev[stageIndex]?.includes(taskIndex)
        ? prev[stageIndex].filter((index) => index !== taskIndex)
        : [...(prev[stageIndex] || []), taskIndex],
    }));
  };

  return (
    <div className="pl-64 mt-4 mx-4">
      <div className="flex items-center mb-3">
        <Button
          id="theme-button"
          className="mr-2 mb-1"
          onClick={() => openModal()}
        >
          <AiOutlinePlus size={24} />
        </Button>
        <h2 className="font-bold text-cprimary ml-2">Top Retail Management</h2>
      </div>
      <hr className="border-pink-500" />

      {/* Table with Templates */}
      <div className="overflow-x-auto">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="w-[3%]">#</th>
              <th className="w-[10%]">Template Name</th>
              <th className="w-[10%]">No of Stages</th>

              <th className="w-[9%]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((template, index) => (
              <tr key={template.id}>
                <td className="w-[3%]">{index + 1}</td>
                <td className="w-[23%]">{template.name}</td>
                <td className="w-[23%]">
                  {Object.keys(template.pipeline).length}
                </td>

                <td className="w-[5%]">
                  <Button
                    variant="warning"
                    onClick={() => openModal(template)}
                    className="mr-2 text-blue-500 hover:text-blue-700"
                  >
                    <AiFillEdit />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => deleteTemplate(template.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <AiFillDelete />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editingTemplateId ? "Edit Template" : "Create Template"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <div className="mb-4">
            <FloatingLabel
              controlId="floatingInput"
              label="Enter template name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                value={templateName}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                  setTemplateNameError("");
                }}
                isInvalid={!!templateNameError} // If there's an error, show red border
              />
              <Form.Control.Feedback type="invalid">
                {templateNameError}
              </Form.Control.Feedback>
            </FloatingLabel>
          </div>
          <div className="space-y-4">
            {stages.map((stage, stageIndex) => (
              <div key={stageIndex}>
                <div className="flex items-center justify-between pl-4 mt-4">
                  <Button
                    onClick={() => toggleStageExpand(stageIndex)}
                    className="d-flex align-items-center p-0 border-0 bg-transparent text-dark"
                    variant="link"
                  >
                    {expandedStages.includes(stageIndex) ? (
                      <GoChevronUp size={20} className="" />
                    ) : (
                      <GoChevronDown size={20} className="" />
                    )}
                  </Button>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={stage.selected}
                      onChange={() => handleStageSelectionChange(stageIndex)}
                      className="sr-only"
                    />
                    <span
                      className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ml-5 ${
                        stage.selected
                          ? "bg-green-500 border-green-500"
                          : "bg-transparent border-gray-300"
                      }`}
                    >
                      <FaCheck
                        className={`text-${
                          stage.selected ? "white" : "gray-400"
                        }`}
                        size={10}
                      />
                    </span>
                  </label>

                  <input
                    type="text"
                    value={stage.name}
                    onChange={(e) =>
                      handleStageNameChange(stageIndex, e.target.value)
                    }
                    placeholder={`Stage ${stageIndex + 1}`}
                    className="ml-6 p-1 border-none outline-none w-full"
                  />

                  <Button
                    onClick={() => deleteStage(stageIndex)}
                    className="ml-2 p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                    variant="link"
                  >
                    <AiFillDelete
                      size={24}
                      className="text-red-500 hover:bg-red-200"
                    />
                  </Button>
                  <Button
                    onClick={() => addTask(stageIndex)}
                    className="p-1 hover:bg-green-200 rounded-full text-green-500 hover:text-green-600"
                    variant="link"
                  >
                    <AiOutlinePlus
                      size={24}
                      className="text-green-500 hover:bg-green-200"
                    />
                  </Button>
                </div>
                {expandedStages.includes(stageIndex) && (
                  <div className="ml-12 mt-2 space-y-4">
                    {stage.tasks.map((task, taskIndex) => (
                      <div
                        key={taskIndex}
                        className={`${
                          taskIndex < stage.tasks.length - 1 ? "mb-[-9px]" : ""
                        }`}
                      >
                        <div className="flex items-center justify-between pl-4">
                          <Button
                            onClick={() =>
                              toggleTaskExpand(stageIndex, taskIndex)
                            }
                            className="d-flex align-items-center p-0 border-0 bg-transparent text-dark ml-[-18px]"
                            variant="link"
                          >
                            {expandedTasks[stageIndex]?.includes(taskIndex) ? (
                              <GoChevronUp size={20} className="mr-5" />
                            ) : (
                              <GoChevronDown size={20} className="mr-5" />
                            )}
                          </Button>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              checked={task.selected}
                              onChange={() =>
                                handleTaskSelectionChange(stageIndex, taskIndex)
                              }
                              className="sr-only"
                            />
                            <span
                              className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ${
                                task.selected
                                  ? "bg-green-500 border-green-500"
                                  : "bg-transparent border-gray-300"
                              }`}
                            >
                              <FaCheck
                                className={`text-${
                                  task.selected ? "white" : "gray-400"
                                }`}
                                size={10}
                              />
                            </span>
                          </label>

                          <input
                            type="text"
                            value={task.name}
                            onChange={(e) =>
                              handleTaskNameChange(
                                stageIndex,
                                taskIndex,
                                e.target.value
                              )
                            }
                            placeholder={`Task ${taskIndex + 1}`}
                            className="ml-6 p-1 border-none outline-none w-full"
                          />

                          <Button
                            onClick={() => deleteTask(stageIndex, taskIndex)}
                            className="ml-2 p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                            variant="link"
                          >
                            <AiFillDelete
                              size={24}
                              className="text-red-500 hover:bg-red-200"
                            />
                          </Button>
                          <Button
                            onClick={() => addSubtask(stageIndex, taskIndex)}
                            className="p-1 hover:bg-green-200 rounded-full text-green-500 hover:text-green-600"
                            variant="link"
                          >
                            <AiOutlinePlus
                              size={24}
                              className="text-green-500 hover:bg-green-200"
                            />
                          </Button>
                        </div>
                        {expandedTasks[stageIndex]?.includes(taskIndex) && (
                          <div className="ml-4 mt-2 space-y-2 ml-16">
                            {" "}
                            {task.subtasks.map((subtask, subtaskIndex) => (
                              <div
                                key={subtaskIndex}
                                className={`flex items-center justify-between ${
                                  subtaskIndex < task.subtasks.length - 1
                                    ? "mb-2"
                                    : ""
                                }`}
                              >
                                <label className="relative inline-flex items-center cursor-pointer ml-10">
                                  <input
                                    type="checkbox"
                                    checked={subtask.selected}
                                    onChange={() =>
                                      handleSubtaskSelectionChange(
                                        stageIndex,
                                        taskIndex,
                                        subtaskIndex
                                      )
                                    }
                                    className="sr-only "
                                  />
                                  <span
                                    className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ${
                                      subtask.selected
                                        ? "bg-green-500 border-green-500"
                                        : "bg-transparent border-gray-300"
                                    }`}
                                  >
                                    <FaCheck
                                      className={`text-${
                                        subtask.selected ? "white" : "gray-400"
                                      }`}
                                      size={10}
                                    />
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  value={subtask.name}
                                  onChange={(e) =>
                                    handleSubtaskNameChange(
                                      stageIndex,
                                      taskIndex,
                                      subtaskIndex,
                                      e.target.value
                                    )
                                  }
                                  placeholder={`Subtask ${subtaskIndex + 1}`}
                                  className="ml-6 p-1 border-none outline-none w-full"
                                />
                                <Button
                                  onClick={() =>
                                    deleteSubtask(
                                      stageIndex,
                                      taskIndex,
                                      subtaskIndex
                                    )
                                  }
                                  className="ml-2 mr-9 p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                                  variant="link"
                                >
                                  <AiFillDelete
                                    size={24}
                                    className="text-red-500 hover:bg-red-200"
                                  />
                                </Button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-xs py-1 px-2 rounded text-black bg-transparent border-0"
            variant="primary"
            onClick={saveJson}
          >
            {editingTemplateId ? "Update" : "Save"}
          </Button>
          <Button
            id="theme-button"
            variant="outline-primary"
            onClick={() =>
              setStages([...stages, { name: "", tasks: [], selected: false }])
            }
          >
            <AiOutlinePlus size={20} />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Templates;
