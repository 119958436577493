class CustomPolygon {
  name: string;
  points: number[][];
  /**
   * Polygon constructor
   * @param {array} points Array of vertices/points of the polygon [lat,lng]
   */
  constructor(points: number[][]) {
    this.name = "Polygon";
    this.points = points;
  }

  /**
   *
   * @returns {obj} Returns the coordinate of the min/max bounds that surounds the polygon
   *                (south-west coordinate, north-east coordinage as in [lat,lng] format)
   */
  getBounds() {
    const { points } = this;
    let arrX = [];
    let arrY = [];

    for (let i in points) {
      arrX.push(points[i][0]);
      arrY.push(points[i][1]);
    }

    return {
      sw: [Math.min.apply(null, arrX), Math.min.apply(null, arrY)],
      ne: [Math.max.apply(null, arrX), Math.max.apply(null, arrY)],
    };
  }

  /**
   * Checks if a point is within the polygon
   * @param {array} point Coordinates of a point [lat,lng]
   * @returns true if point is within, false otherwhise
   */
  contains(point: number[]) {
    const x = point[0];
    const y = point[1];
    const bounds = this.getBounds();

    // Check if point P lies within the min/max boundary of our polygon
    if (
      x < bounds.sw[0] ||
      x > bounds.ne[0] ||
      y < bounds.sw[1] ||
      y > bounds.ne[1]
    )
      return false;

    let intersect = 0;
    const { points } = this;

    // Geofencing method (aka Even–odd rule)
    // See more at: https://en.wikipedia.org/wiki/Even%E2%80%93odd_rule
    // Now for each path of our polygon we'll count how many times our imaginary
    // line crosses our paths, if it crosses even number of times, our point P is
    // outside of our polygon, odd number our point is within our polygon
    for (let i = 0; i < points.length; i++) {
      // Check if pont P lies on a vertices of our polygon
      if (x === points[i][0] && y === points[i][1]) return true;

      let j = i !== points.length - 1 ? i + 1 : 0;

      // Check if Py (y-component of our point P) is with the y-boundary of our path
      if (
        (points[i][1] < points[j][1] &&
          y >= points[i][1] &&
          y <= points[j][1]) ||
        (points[i][1] > points[j][1] && y >= points[j][1] && y <= points[i][1])
      ) {
        // Check if Px (x-componet of our point P) crosses our path
        let sx =
          points[i][0] +
          ((points[j][0] - points[i][0]) * (y - points[i][1])) /
            (points[j][1] - points[i][1]);
        if (sx >= x) intersect += 1;
      }
    }
    return intersect % 2 === 0 ? false : true;
  }
}

export default CustomPolygon;
