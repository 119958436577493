import { FcGoogle } from "react-icons/fc";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import { useAuth } from "@Contexts/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BioretailPNG from "@Assets/images/bioretail.png";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const { login, user, continueWithGoogle } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  return (
    <div className="bg-csecondary h-[calc(100vh-70px)] pt-[10%] flex-auto">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5" style={{ textAlign: "center" }}>
            <div className="form-signin">
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  await login(email, password).catch((err) => {
                    setErr(
                      err.message
                        .replace("Firebase", "")
                        .replace(":", "")
                        .trim()
                    );
                  });
                  setLoading(false);
                }}
                id="login-form"
              >
                <h2 className="font-bold mb-4">LOGIN</h2>
                <FloatingLabel
                  controlId="floatingInput-email"
                  label="Email address"
                  className="mb-2"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput-password"
                  label="Password"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>
                <Button
                  className="w-100"
                  variant="light"
                  onClick={() =>
                    continueWithGoogle().catch((err) => setErr(err.message))
                  }
                >
                  <div className="flex gap-2 items-center justify-center">
                    Continue with
                    <div className="flex items-center">
                      <FcGoogle className="text-lg" />
                      oogle
                    </div>
                  </div>
                </Button>
                <hr />
                {err && (
                  <>
                    <Alert variant="danger" style={{ textAlign: "left" }}>
                      {err}
                    </Alert>
                    <hr />
                  </>
                )}
                <Button
                  id="theme-button"
                  type="submit"
                  disabled={loading}
                  className="w-100 btn btn-lg btn-warning disabled:opacity-40"
                >
                  LOGIN
                </Button>
                
                <div className="flex items-center justify-center mt-5 mb-3">
  <p className="text-muted  mr-2">Powered By</p>
  <img style={{marginTop: "-20px"} }src={BioretailPNG} className="w-[5vw] h-full" />
</div>

                
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
