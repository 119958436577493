// import React from "react";

import { useAuth } from "@Contexts/AuthContext";

export default function Dashboard() {
  const { user } = useAuth();
  return (
    <div
      style={{
        paddingLeft: 250,
      }}
    >
      <div className="main-content">
        <h1 className="text-center mt-5">Dashboard</h1>
        <p className="text-center">Bienvenido a Locales Fantasticos app</p>
        <p className="text-center">
          Logged In as: <span id="mono">{user?.email}</span>
        </p>
        <p className="text-center error"></p>
      </div>
    </div>
  );
}
