import {
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  signInAnonymously,
} from "firebase/auth";
import React, { createContext, useContext, useEffect } from "react";
import { exchange_auth, auth, db } from "@Config/firebase";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import PageLoading from "@Components/PageLoading";
import { setUser as setSentryUser } from "@sentry/react";

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

interface LocalUser extends User {
  data: any;
}

const provider = new GoogleAuthProvider();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const [user, setUser] = React.useState<LocalUser | null>(null);
  const [loading, setLoading] = React.useState(true);

  async function signup(data: any, userType: string) {
    const userRef = await createUserWithEmailAndPassword(
      exchange_auth,
      data.email,
      data.password
    );
    await setDoc(doc(db, `users/${userRef.user.uid}`), {
      firstName: data.firstName,
      lastName: data.lastName,
      email: userRef.user.email,
      role: userType,
    });

    await signInAnonymous();

    return userRef.user.uid;
  }

  async function login(email: string, password: string) {
    await signInWithEmailAndPassword(exchange_auth, email, password);
    await signInAnonymous();
  }

  async function continueWithGoogle() {
    await signInWithPopup(exchange_auth, provider).then(async (result) => {
      const user = result.user;
      if ((await getDoc(doc(db, `users/${user.uid}`))).exists()) {
        await signInAnonymous();
      } else {
        await user.delete();
        throw new Error("User not found.");
      }
    });
  }

  function logout() {
    return signOut(exchange_auth);
  }

  async function signInAnonymous() {
    await signInAnonymously(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      exchange_auth,
      async (currentUser: any) => {
        if (currentUser) {
          await signInAnonymous();
          onSnapshot(doc(db, `users/${currentUser.uid}`), (snapshot) => {
            const data = snapshot.data();
            if (data?.role) {
              setUser({ ...currentUser, data });
              setSentryUser({
                id: currentUser.id,
                email: currentUser.email ?? undefined,
              });
            }
            setLoading(false);
          });
        } else {
          setUser(null);
          setSentryUser(null);
          setLoading(false);
        }
      }
    );

    return unsubscribe;
  }, []);

  const value: AuthContextProps = {
    user,
    logout,
    login,
    signup,
    continueWithGoogle,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <PageLoading />}
    </AuthContext.Provider>
  );
}
