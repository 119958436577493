import { useEffect, useState } from "react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db, storage } from "@Config/firebase";
import { useAuth } from "@Contexts/AuthContext";
import { AiFillCamera } from "react-icons/ai";
import { Form, FloatingLabel, Button } from "react-bootstrap";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export default function Profile() {
  const [dbUser, setDbUser] = useState<any>({
    profileImage: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    areaOfInfluence: "",
  });
  const [imageSelector, setImageSelector] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      const docRef = await getDoc(doc(db, `users/${user.uid}`));
      setDbUser(docRef?.data());
    })();
  }, [user]);

  return (
    <div
      className="container"
      style={{
        paddingLeft: 250,
      }}
    >
      <div className="main-content">
        <h1 className="text-center mt-5">Perfil</h1>
        <p className="text-center">Bienvenido al perfil de usuario</p>
      </div>
      <br />
      <br />
      <div>
        <Form
          style={{
            display: "flex",
            gap: 70,
          }}
        >
          <div style={{ width: "20%" }}>
            <div
              onClick={() => imageSelector.click()}
              style={{
                cursor: "pointer",
                width: 150,
                height: 150,
                background: "rgba(0,0,0,0.1)",
                borderRadius: 9999,
                display: "grid",
                placeContent: "center",
                margin: "auto",
              }}
            >
              <input
                type="file"
                style={{ position: "absolute", opacity: 0 }}
                accept="image/*"
                onChange={(e) =>
                  setDbUser({ ...dbUser, profileImage: e.target?.files?.[0] })
                }
                ref={(input) => setImageSelector(input)}
              />
              {dbUser.profileImage ? (
                <img
                  src={
                    typeof dbUser.profileImage === "string"
                      ? dbUser.profileImage
                      : URL.createObjectURL(dbUser.profileImage)
                  }
                  style={{
                    width: 150,
                    height: 150,
                    borderRadius: 9999,
                  }}
                />
              ) : (
                <AiFillCamera style={{ fontSize: 50 }} />
              )}
            </div>
          </div>
          <div style={{ width: "75%" }}>
            <Form.Group
              style={{
                display: "flex",
                gap: 12,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FloatingLabel label="First Name" style={{ width: "100%" }}>
                <Form.Control
                  type="text"
                  defaultValue={dbUser.firstName}
                  onChange={(e) =>
                    setDbUser({ ...dbUser, firstName: e.target.value.trim() })
                  }
                />
              </FloatingLabel>
              <FloatingLabel label="Second Name" style={{ width: "100%" }}>
                <Form.Control
                  type="text"
                  defaultValue={dbUser.lastName}
                  onChange={(e) =>
                    setDbUser({ ...dbUser, lastName: e.target.value.trim() })
                  }
                />
              </FloatingLabel>
            </Form.Group>

            <FloatingLabel label="Email" style={{ marginTop: 8 }}>
              <Form.Control
                type="email"
                defaultValue={dbUser.email}
                onChange={(e) =>
                  setDbUser({ ...dbUser, email: e.target.value.trim() })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Phone" style={{ marginTop: 8 }}>
              <Form.Control
                type="number"
                defaultValue={dbUser.phone}
                onChange={(e) =>
                  setDbUser({ ...dbUser, phone: e.target.value.trim() })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Area of influence" style={{ marginTop: 8 }}>
              <Form.Control
                type="text"
                defaultValue={dbUser.areaOfInfluence}
                onChange={(e) =>
                  setDbUser({
                    ...dbUser,
                    areaOfInfluence: e.target.value.trim(),
                  })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Account Type" style={{ marginTop: 8 }}>
              <Form.Control
                type="text"
                style={{ cursor: "default", pointerEvents: "none" }}
                value={
                  dbUser.role?.charAt(0).toUpperCase() + dbUser.role?.slice(1)
                }
              />
            </FloatingLabel>
            <Button
              className="w-100 mt-4"
              disabled={loading}
              id="theme-button"
              onClick={async () => {
                setLoading(true);
                if (
                  dbUser.profileImage &&
                  typeof dbUser.profileImage !== "string"
                ) {
                  const ppRef = ref(
                    storage,
                    `User-Data/Profile-Images/${user.uid}.${
                      dbUser.profileImage.type.split("/")[1]
                    }`
                  );
                  const ppSnapshot = await uploadBytes(
                    ppRef,
                    dbUser.profileImage
                  );
                  const downloadURL = await getDownloadURL(ppSnapshot.ref);
                  await updateDoc(doc(db, `users/${user.uid}`), {
                    ...dbUser,
                    profileImage: downloadURL,
                  });
                } else {
                  await updateDoc(doc(db, `users/${user.uid}`), dbUser);
                }
                setLoading(false);
              }}
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
