import { IoMdDoneAll } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { Form, Button, Modal, FloatingLabel, Table } from "react-bootstrap";
import { FormEvent, useState } from "react";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "@Config/firebase";
import { useAuth } from "@Contexts/AuthContext";
import { useData } from "@Contexts/DataContext";
import { useNavigate } from "react-router-dom";
import DefaultOrganization from "@Utility/DefaultOrganization";

export default function Organizations() {
  const [show, setShow] = useState(false);

  const { organizations } = useData();
  const { user } = useAuth();

  const navigate = useNavigate();

  return (
    <>
      <div style={{ paddingLeft: 250 }} className="mt-4 mx-4">
        <div className="flex justify-between items-center">
          <h2 className="font-bold text-cprimary">Organizations</h2>
          <Button id="theme-button" onClick={() => setShow(true)}>
            <IoMdAdd />
          </Button>
        </div>
        <hr style={{ border: "1px rgb(255 29 127) solid" }} />
        {organizations.length ? (
          <Table hover>
            <thead>
              <tr>
                <th>Default</th>
                <th>Name</th>
                <th>Description</th>
                <th>Role</th>
                <th>Credits</th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((organization) => (
                <tr
                  key={organization.id}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/manager/organizations/${organization.id}`)
                  }
                >
                  <td>
                    {user?.data?.default_organization === organization.id ? (
                      <IoMdDoneAll className="mt-[2px]" />
                    ) : (
                      <Button
                        id="theme-button"
                        size="sm"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await DefaultOrganization.set(
                            organization.id,
                            user.uid
                          );
                        }}
                      >
                        Set
                      </Button>
                    )}
                  </td>
                  <td>{organization.name}</td>
                  <td>{organization.description}</td>
                  <td>
                    {
                      organization?.members?.filter(
                        (member: any) => member.uid === user.uid
                      )[0]?.role
                    }
                  </td>
                  <td
                    style={{
                      color: organization.credits ? "black" : "red",
                      fontWeight: organization.credits ? "normal" : "bold",
                    }}
                  >
                    {organization?.members?.filter(
                      (member: any) => member.uid === user.uid
                    )[0]?.role === "owner"
                      ? organization.credits
                      : organization?.members?.filter(
                          (member: any) => member.uid === user.uid
                        )[0]?.credits}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center opacity-80">
            You don't have any organizations.
          </div>
        )}
      </div>
      {show && <AddModal show={show} onHide={() => setShow(false)} />}
    </>
  );
}

const AddModal = ({ show, onHide }: any) => {
  const [data, setData] = useState({});
  const { user } = useAuth();
  const create = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const org = await addDoc(collection(db, "organizations"), {
      ...data,
      transactions: [],
      credits: 10,
    });
    await setDoc(doc(db, `organizations/${org.id}/members/${user.uid}`), {
      uid: user.uid,
      role: "owner",
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Organization</Modal.Title>
      </Modal.Header>
      <Form onSubmit={create}>
        <Modal.Body>
          <FloatingLabel label="Name" className="mb-2">
            <Form.Control
              onChange={(e) => setData({ ...data, name: e.target.value })}
              required
            />
          </FloatingLabel>
          <FloatingLabel label="Description">
            <Form.Control
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              required
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" type="button" onClick={onHide}>
            Close
          </Button>
          <Button id="theme-button" type="submit">
            Create
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
