import { db } from "@Config/firebase";
import { useAuth } from "@Contexts/AuthContext";
import { useData } from "@Contexts/DataContext";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

export default function InvitationPage() {
  const { id } = useParams();
  const {
    invitations,
    allOrganizations: organizations,
    getOrganizations,
  } = useData();
  const { user, signup } = useAuth();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const [invitation, setInvitation] = useState(
    invitations.filter((invitation) => invitation.id === id)?.[0]
  );
  const [organization, setOrganization] = useState(
    organizations.filter(
      (organization) => organization.id === invitation?.organization
    )?.[0]
  );

  useEffect(() => {
    const invitation = invitations.filter(
      (invitation) => invitation.id === id
    )?.[0];
    setInvitation(invitation);
  }, [invitations]);

  useEffect(() => {
    if (invitation?.email) {
      const organization = organizations.filter(
        (organization) => organization.id === invitation.organization
      )?.[0];
      setOrganization(organization);
    }
  }, [organizations, invitation]);

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, []);

  if (!invitation?.email || !organization?.name) {
    return <NotInvitation />;
  }
  return (
    <div className="bg-csecondary h-[calc(100vh-70px)] pt-[10%] flex-auto">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5" style={{ textAlign: "center" }}>
            <div className="form-signin">
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  const uid = await signup(
                    { email: invitation.email, password, firstName, lastName },
                    "manager"
                  ).catch((err) =>
                    setErr(
                      err.message
                        .replace("Firebase", "")
                        .replace(":", "")
                        .trim()
                    )
                  );
                  await getOrganizations();
                  await setDoc(
                    doc(db, `organizations/${organization.id}/members/${uid}`),
                    {
                      uid,
                      role: "member",
                      credits: 0,
                    }
                  );
                  await deleteDoc(doc(db, `invitations/${id}`));
                  window.location.replace("/dashboard");
                  // navigate("/dashboard");
                  setLoading(false);
                }}
                action=""
                id="form-signup"
              >
                <h4 className="font-bold mb-2 text-cprimary">
                  {organization.name}
                </h4>
                <h2 className="font-bold mb-4">Create Your Account</h2>
                <div className="flex gap-2">
                  <FloatingLabel label="First Name" className="mb-2 w-full">
                    <Form.Control
                      className="form-control"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Last Name" className="mb-2 w-full">
                    <Form.Control
                      className="form-control"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FloatingLabel>
                </div>

                <FloatingLabel label="Password" className="mb-3">
                  <Form.Control
                    type="password"
                    className="form-control"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>

                <hr />
                {err && (
                  <>
                    <Alert variant="danger" style={{ textAlign: "left" }}>
                      {err}
                    </Alert>
                    <hr />
                  </>
                )}
                <Button
                  className="w-100"
                  id="theme-button"
                  size="lg"
                  type="submit"
                  disabled={loading || !password || !firstName || !lastName}
                >
                  Registrate
                </Button>
                <p className="mt-5 mb-3 text-muted">© BioRetail 2023</p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const NotInvitation = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen bg-csecondary h-[calc(100vh-70px)] grid place-items-center">
      <div className="grid place-items-center">
        <h1 className="font-bold">No Invitation</h1>
        <h3>
          Either this invitation has already been used or is no longer
          available.
        </h3>
        <br />
        <Button id="theme-button" onClick={() => navigate("/")}>
          Go Home
        </Button>
      </div>
    </div>
  );
};
