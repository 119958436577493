import { AiFillEdit } from "react-icons/ai";
import { AiFillFolderOpen } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import "firebase/compat/storage";
import {
  Button,
  FloatingLabel,
  Modal,
  Form,
  ProgressBar,
  Alert,
  Table,
} from "react-bootstrap";
import DragDropFile from "@Components/DragDropFile";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "@Config/firebase";
import { useNavigate } from "react-router-dom";
import { useData } from "@Contexts/DataContext";

export default function Databases() {
  const [show, setShow] = useState(false);
  const [datasets, setDatasets] = useState<any[]>([]);
  const [refetchVal, setRefetchVal] = useState(0);
  const [confirmDelModal, setConfirmDelModal] = useState({
    show: false,
    id: "",
  });
  const [editModal, setEditModal] = useState({
    show: false,
    entry: {},
  });

  const navigate = useNavigate();

  const { categories, retails } = useData();

  const getDatasets = async () => {
    const data = (await getDocs(collection(db, "retail"))).docs.map(
      (doc: any) => ({
        ...doc.data(),
        id: doc.id,
      })
    );
    setDatasets(data);
  };

  useEffect(() => {
    getDatasets();
  }, [refetchVal]);

  return (
    <>
      <div
        style={{
          padding: "0px 70px 0px 320px",
        }}
      >
        <div className="main-content">
          <h1 className="text-center mt-5">Databases</h1>
        </div>
        <div className="flex items-center justify-between">
          <Button id="theme-button" onClick={() => setShow(true)}>
            Upload Database
          </Button>
          <Button
            id="theme-button"
            onClick={() => navigate("/admin/databases/categories")}
          >
            Categories
          </Button>
        </div>
        <Table striped bordered className="mt-4">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Pin</th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Category</th>
              <th scope="col">Price</th>
              <th scope="col">Total Entries</th>
              <th scope="col">Options</th>
            </tr>
          </thead>
          <tbody>
            {datasets.map((dataset: any, idx: number) => (
              <tr key={idx}>
                <th scope="row">{idx + 1}</th>
                <th>
                  <img src={dataset.pin} width="30" />
                </th>
                <th>{dataset.name}</th>
                <th>{dataset.description}</th>

                <th>
                  {
                    categories.filter((cate) => cate.id === dataset.category)[0]
                      ?.name
                  }
                </th>
                <th>{dataset.price} </th>
                <td>
                  {retails.map((retail: any) => (
                    <>
                      {retail.id === dataset.id && (
                        <>
                          <span className="font-semibold">
                            {retail.locations}
                          </span>
                        </>
                      )}
                    </>
                  ))}
                </td>
                <th style={{ display: "flex", justifyItems: "center", gap: 8 }}>
                  <Button
                    onClick={() => {
                      console.log(dataset.id);
                      navigate(`/admin/databases/${dataset.id}`);
                    }}
                  >
                    <AiFillFolderOpen />
                  </Button>

                  <Button
                    variant="warning"
                    onClick={() => setEditModal({ entry: dataset, show: true })}
                  >
                    <AiFillEdit />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() =>
                      setConfirmDelModal({ id: dataset.id, show: true })
                    }
                  >
                    <AiFillDelete />
                  </Button>
                </th>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {show && (
        <UploadDatabase
          show={show}
          handleClose={() => setShow(false)}
          setRefetchVal={setRefetchVal}
        />
      )}
      <ConfirmDelete
        show={confirmDelModal.show}
        id={confirmDelModal.id}
        setRefetchVal={setRefetchVal}
        handleClose={() => setConfirmDelModal({ id: "", show: false })}
      />
      <EditModal
        show={editModal.show}
        entry={editModal.entry}
        refresh={() => setRefetchVal(Math.random())}
        handleClose={() => setEditModal({ entry: {}, show: false })}
      />
    </>
  );
}

const UploadDatabase = ({ show, handleClose, setRefetchVal }: any) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadCount, setUploadCount] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");

  const { categories } = useData();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Database</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            const data = JSON.parse((await file?.text()) || "");
            setDataLength(data.length);
            const databaseDocumentRef = await addDoc(collection(db, "retail"), {
              name,
              description,
              category,
              timestamp: new Date(),
            });
            const promises = data.map(async (object: any, idx: number) => {
              await addDoc(
                collection(db, `retail/${databaseDocumentRef.id}/data`),
                object
              );
              setUploadCount(idx + 1);
            });
            await Promise.all(promises);
            // setLoading(false);
            setTimeout(() => {
              setRefetchVal(Math.random());
              handleClose();
            }, 2500);
          }}
        >
          <FloatingLabel label="Name">
            <Form.Control onChange={(e) => setName(e.target.value)} required />
          </FloatingLabel>
          <div style={{ marginBottom: 8 }} />
          <FloatingLabel label="Description">
            <Form.Control
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </FloatingLabel>
          <div style={{ marginBottom: 8 }} />
          <FloatingLabel label="Category">
            <Form.Select onChange={(e) => setCategory(e.target.value)}>
              <option selected hidden>
                -- Select --
              </option>
              {categories.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <div style={{ marginBottom: 8 }} />
          <DragDropFile setFile={setFile} file={file} />
          {loading && (
            <>
              <ProgressBar
                className="mt-4"
                animated={loading}
                now={uploadCount}
                max={dataLength}
                label={loading ? "Uploading..." : "Upload Complete"}
              />
              <Alert variant="danger" className="mt-4">
                Do not close this tab or upload will be interrupted.
              </Alert>
            </>
          )}
          <Button
            id="theme-button"
            className="w-100 mt-2"
            type="submit"
            disabled={loading}
          >
            Upload
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const ConfirmDelete = ({ show, handleClose, setRefetchVal, id }: any) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this dataset.</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={async () => {
            await deleteDoc(doc(db, `retail/${id}`));
            setRefetchVal(Math.random());
            handleClose();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditModal = ({ handleClose, show, entry, refresh }: any) => {
  const [newValues, setNewValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { uploadFile } = useData();
  const [category, setCategory] = useState(entry.category || "");
  const [showPopup, setShowPopup] = useState(false);
  const [_fileSelected, setFileSelected] = useState(false);
  const [svgUrl, setSvgUrl] = useState("");
  const [_selectedSvg, setSelectedSvg] = useState<string | null>(null);
  const [_borderClass, setBorderClass] = useState("");
  const [price, setPrice] = useState<number>(entry.price);

  const { categories } = useData();
  const [pins, setPins] = useState<string[]>([]);

  useEffect(() => {
    const fetchPins = async () => {
      try {
        const directoryRef = ref(storage, "Retails/Pins/");
        const items = await listAll(directoryRef);
        const downloadURLPromises = items.items.map(async (item) => {
          return await getDownloadURL(item);
        });

        const downloadURLs = await Promise.all(downloadURLPromises);
        setPins(downloadURLs);
      } catch (error) {
        console.error("Error fetching pins:", error);
      }
    };

    if (!show) fetchPins();
  }, [show]);

  const handlePinSelection = (selectedPin: string) => {
    if (_selectedSvg === selectedPin) {
      setSelectedSvg(null);
      setNewValues({
        ...newValues,
        pin: "",
      });
      return;
    }

    // Select newly clicked pin
    setSelectedSvg(selectedPin);
    setNewValues({
      ...newValues,
      pin: selectedPin,
    });
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setFileSelected(false);
  };

  return (
    <>
      <Modal
        onHide={() => {
          handlePopupClose();
          handleClose();
          setSvgUrl("");
        }}
        show={show}
        // backdrop={showPopup ? false : "static"}
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title>Edit Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            style={{ display: "flex", flexDirection: "column", gap: 4 }}
            onSubmit={async (e) => {
              e.preventDefault();
              setLoading(true);
              let data: any = {
                ...entry,
                ...newValues,
              };

              if (category) data.category = category;

              if (data.pin instanceof File) {
                try {
                  const timestamp = Date.now();
                  const pinRef = `Retails/Pins/pin-${timestamp}.svg`;
                  data.pin = await uploadFile(data.pin, pinRef);
                } catch (error) {
                  console.error("Error uploading pin:", error);
                  return;
                }
              }

              if (price) {
                data.price = Math.round(price);
              }

              await updateDoc(doc(db, `retail/${entry.id}`), data);

              refresh();
              setLoading(false);
              handleClose();
            }}
          >
            <FloatingLabel label="Name">
              <Form.Control
                defaultValue={entry.name}
                onChange={(e) =>
                  setNewValues({ ...newValues, name: e.target.value })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Description">
              <Form.Control
                defaultValue={entry.description}
                onChange={(e) =>
                  setNewValues({ ...newValues, description: e.target.value })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Price (in credits)">
              <Form.Control
                type="number"
                defaultValue={entry.price}
                onChange={(e) => setPrice(Number(e.target.value))}
                placeholder="Enter price"
              />
            </FloatingLabel>

            <FloatingLabel label="Category">
              <Form.Select
                defaultValue={entry.category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option selected={!entry.category} hidden>
                  -- Select --
                </option>
                {categories.map((category) => (
                  <option
                    key={category.id}
                    value={category.id}
                    selected={entry.category === category.name}
                  >
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>

            <strong style={{ marginLeft: 3 }}>Pin:</strong>
            {(svgUrl || entry.pin || _selectedSvg) && (
              <div style={{ marginLeft: 10, marginTop: -10 }}>
                <img
                  src={svgUrl || _selectedSvg || entry.pin}
                  alt="Pin"
                  style={{ width: "65px", height: "65px", margin: "5px" }}
                />
              </div>
            )}
            {showPopup && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  zIndex: 1000,
                }}
              />
            )}
            <Button
              style={{ width: "fit-content" }}
              id="theme-button-outline"
              onClick={() => setShowPopup(true)}
              className="mb-2"
              size="sm"
              variant="outline"
            >
              {entry.pin ? "Change Pin" : "Select Pin"}
            </Button>

            <Modal
              style={{ marginTop: 71 }}
              show={showPopup}
              onHide={handlePopupClose}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Pins</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  className="pin-container"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {pins.map((pin, index) => (
                    <div
                      key={index}
                      className={`file-input-icon flex items-center justify-center rounded-md p-2 border-cprimary ${
                        _selectedSvg === pin
                          ? "border-4 border-cprimary"
                          : "border"
                      }`}
                      style={{ width: "58px", height: "58px", margin: "5px" }}
                      onClick={() => handlePinSelection(pin)}
                    >
                      <img
                        src={pin}
                        alt="Pin"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </div>
                  ))}

                  <label
                    style={{ marginLeft: 5, marginTop: 5 }}
                    htmlFor="file-input"
                    className="file-input-label"
                  >
                    <input
                      type="file"
                      id="file-input"
                      accept=".svg"
                      onChange={async (e) => {
                        const files = e.target.files;
                        if (files && files.length > 0) {
                          const url = URL.createObjectURL(files[0]);
                          setNewValues({
                            ...newValues,
                            pin: files[0],
                          });
                          setSvgUrl(url);
                          setSelectedSvg(null);
                          setBorderClass("border-grey-500 border-4");
                        }
                      }}
                      className="hidden"
                    />
                    <div
                      className={`file-input-icon flex items-center justify-center rounded-md p-2 ${
                        svgUrl ? "border-4 border-cprimary" : "border"
                      }`}
                    >
                      {svgUrl && (
                        <img
                          src={svgUrl}
                          alt="Selected SVG"
                          style={{ maxWidth: "35px", maxHeight: "35px" }}
                        />
                      )}
                      {!svgUrl && (
                        <svg
                          className="w-10 h-10 text-gray-400 cursor-pointer"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      )}
                    </div>
                  </label>
                </div>
                <strong style={{ marginLeft: 3 }}>Maximun Size: 35x35 </strong>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="theme-button"
                  variant="secondary"
                  onClick={handlePopupClose}
                >
                  Select
                </Button>
              </Modal.Footer>
            </Modal>

            <hr />
            <Button id="theme-button" type="submit" disabled={loading}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
