import React, { useEffect, useState } from "react";
import { Accordion, Alert } from "react-bootstrap";
import { AiOutlineLoading, AiOutlineDown } from "react-icons/ai";
import CustomPolygon from "./CustomPolygon";
import { useData } from "@Contexts/DataContext";

const Places: React.FC<PlacesProps> = ({ map, points, places, setPlaces }) => {
  const [isochrone, setIsochrone] = useState(new CustomPolygon(points));
  const { place } = useData();
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(true);
  const [expanded, expand] = useState<any>(false);

  async function findPlaces() {
    if (map && place) {
      setLoading(true);
      const service = new google.maps.places.PlacesService(map);

      const bounds = new google.maps.LatLngBounds();
      isochrone.points.map((point) =>
        bounds.extend(new google.maps.LatLng(point[1], point[0]))
      );

      const filteredPlaces = place.filter(
        (place: any) => place.status === true
      );

      const typeResults: any[] = [];

      filteredPlaces.forEach((place: any) => {
        const request: google.maps.places.FindPlaceFromQueryRequest = {
          // bounds: bounds,
          // location: new google.maps.LatLng(center.lat, center.lng),
          query: place.name,
          fields: ["name", "geometry"],
          locationBias: bounds,
          // type: placeType.type,
          // radius: 0,
          // page_token: 5,
        };

        service.findPlaceFromQuery(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            results = results.filter((result: google.maps.places.PlaceResult) =>
              isochrone.contains([
                result?.geometry?.location?.lng?.() || 0,
                result?.geometry?.location?.lat?.() || 0,
              ])
            );

            typeResults.push({
              type: place.type,
              name: place.name,
              length: results?.length || 0,
              results: results,
            });
          } else if (status !== google.maps.places.PlacesServiceStatus.OK) {
            setErr("Places data could not be fetched.");
          }
        });
      });

      setTimeout(() => {
        if (typeResults.length) {
          setPlaces(typeResults);
        }
      }, 3000);
    }
  }

  useEffect(() => {
    setIsochrone(new CustomPolygon(points));
  }, [points]);

  useEffect(() => {
    if (isochrone?.points?.length >= 4 && places.length === 0) {
      findPlaces();
    }
  }, [isochrone.points, places]);

  useEffect(() => {
    if (places.length > 0) setLoading(false);
  }, [places]);

  if (err)
    return (
      <Alert
        variant="warning"
        className="flex gap-2 items-center text-sm"
        style={{ padding: "10px 15px" }}
      >
        {err}
      </Alert>
    );
  if (!map || isochrone?.points?.length < 4) return <></>;

  return (
    <>
      <div>
        {!loading ? (
          <>
            <div
              onClick={() => expand(!expanded)}
              className="bg-white p-2 rounded-xl flex items-center gap-2 w-[550px]"
            >
              <span className="w-full text-sm">Places</span>
              {/* <OverlayTrigger
                overlay={
                  <Tooltip id="button-tooltip">
                    {!toggleBounds ? "See" : "Hide"} search area
                  </Tooltip>
                }
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggleBounds(!toggleBounds);
                  }}
                >
                  {toggleBounds ? <AiFillEyeInvisible /> : <AiFillEye />}
                </div>
              </OverlayTrigger> */}
              <AiOutlineDown style={{ rotate: expanded ? "180deg" : "0deg" }} />
            </div>
          </>
        ) : (
          <AiOutlineLoading className="animate-spin text-sm mt-2 ml-3 text-white" />
        )}
        {expanded && (
          <div className="w-[550px] p-2 bg-white rounded-xl -mt-[14px]">
            <Accordion defaultActiveKey={"0"} className="mt-2">
              {[...new Set(places.map((place) => place.type))].map((type) => (
                <Accordion.Item key={type} eventKey={type}>
                  <Accordion.Header>
                    <span className="capitalize">{type.replace("_", " ")}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="flex flex-col gap-2">
                      {places
                        .filter((place) => place.type === type)
                        .map((place) => (
                          <div key={place.name}>
                            {place.name} ({place.length})
                          </div>
                        ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        )}
      </div>
    </>
  );
};

export default Places;
