import { AiFillDelete } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  limit,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Modal, Table, Form, FloatingLabel } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "@Config/firebase";

export default function Database() {
  const { id } = useParams();
  const [data, setData] = useState<any>([]);
  const [dataset, setDataset] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [editModalShow, setEditModalShow] = useState({
    entry: {},
    show: false,
  });
  let [refreshVal, setRefreshVal] = useState(0);

  const refresh = () => setRefreshVal(Math.random());

  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    setDataset((await getDoc(doc(db, `retail/${id}`))).data());

    setData(
      (
        await getDocs(query(collection(db, `retail/${id}/data`), limit(15)))
      ).docs.map((document) => ({ ...document.data(), id: document.id }))
    );
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [refreshVal]);

  if (loading)
    return (
      <div
        style={{
          padding: "70px 70px 0px 320px",
        }}
      >
        Loading...
      </div>
    );

  return (
    <div
      style={{
        padding: "0px 70px 0px 320px",
      }}
    >
      <div className="flex gap-4 items-center mt-4">
        <Button id="theme-button" onClick={() => navigate(-1)}>
          <BiArrowBack />
        </Button>
        <h4 className="mt-2">{dataset.name}</h4>
      </div>
      <Table striped bordered className="mt-4">
        <thead>
          <tr>
            <th scope="col">#</th>
            {Object.keys(data[0]).map((keyName) => (
              <th scope="col">{keyName}</th>
            ))}
            <th scope="col">Options</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry: any, idx: number) => (
            <tr key={idx}>
              <th scope="row">{idx + 1}</th>
              {Object.keys(data[0]).map((keyName) => (
                <th scope="row">{entry[keyName]}</th>
              ))}
              <th style={{ display: "flex", gap: 4 }}>
                <Button
                  variant="danger"
                  onClick={async () => {
                    await deleteDoc(doc(db, `retail/${id}/data/${entry.id}`));
                    refresh();
                  }}
                >
                  <AiFillDelete />
                </Button>
                <Button onClick={() => setEditModalShow({ entry, show: true })}>
                  <AiFillEdit />
                </Button>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
      <EditModal
        show={editModalShow.show}
        entry={editModalShow.entry}
        datasetId={id}
        refresh={refresh}
        handleClose={() => setEditModalShow({ ...editModalShow, show: false })}
      />
    </div>
  );
}

const EditModal = ({ handleClose, show, entry, datasetId, refresh }: any) => {
  const [newValues, setNewValues] = useState({});
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Modal onHide={handleClose} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            style={{ display: "flex", flexDirection: "column", gap: 4 }}
            onSubmit={async (e) => {
              e.preventDefault();
              setLoading(true);
              await updateDoc(doc(db, `retail/${datasetId}/data/${entry.id}`), {
                ...entry,
                ...newValues,
              });
              refresh();
              setLoading(false);
              handleClose();
            }}
          >
            {Object.keys(entry).map((keyName: any, idx: number) => {
              if (keyName === "id") return <></>;
              return (
                <FloatingLabel label={keyName} key={idx}>
                  <Form.Control
                    defaultValue={entry[keyName]}
                    onChange={(e) =>
                      setNewValues({ ...newValues, [keyName]: e.target.value })
                    }
                  />
                </FloatingLabel>
              );
            })}
            <Button id="theme-button" type="submit" disabled={loading}>
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
