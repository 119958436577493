import { useState, useEffect } from "react";
import { useData } from "@Contexts/DataContext";
import { useAuth } from "@Contexts/AuthContext";
import { AiFillDelete } from "react-icons/ai";
import { MdWarning } from "react-icons/md"; // Add hazard icon
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "@Config/firebase";
import { Table, Button, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const calculateProgress = (pipeline: any): number => {
  let totalItems = 0;
  let completedItems = 0;

  if (pipeline && typeof pipeline === "object") {
    Object.values(pipeline as { [key: string]: any }).forEach((stage) => {
      if (typeof stage === "boolean") {
        totalItems += 1;
        if (stage) completedItems += 1;
      } else {
        Object.values(stage as { [key: string]: any }).forEach((task) => {
          if (typeof task === "boolean") {
            totalItems += 1;
            if (task) completedItems += 1;
          } else {
            const subtasks = Object.values(task as { [key: string]: any });
            totalItems += subtasks.length;
            completedItems += subtasks.filter((subtask) => subtask).length;
          }
        });
      }
    });
  }

  return totalItems === 0 ? 0 : (completedItems / totalItems) * 100;
};

const getProgressBarVariant = (progress: number): string => {
  if (progress <= 25) return "danger";
  if (progress <= 50) return "warning";
  if (progress <= 75) return "primary";
  return "success";
};

const getHazardIcon = (pipeline: any): boolean => {
  const today = new Date();
  let hasHazard = false;

  const extractEndDates = (obj: any) => {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      Object.values(obj).forEach((value) => {
        if (typeof value === "boolean") return;

        if (typeof value === "object" && value !== null) {
          const { endDate } = value as { endDate?: string };

          if (endDate) {
            const endDateObj = new Date(endDate);
            if (endDateObj < today) hasHazard = true;
          }

          extractEndDates(value);
        }
      });
    }
  };

  extractEndDates(pipeline);
  return hasHazard;
};

export default function Projects() {
  const { locales, projects } = useData();
  const { user } = useAuth();
  const [projectWithLocales, setProjectWithLocales] = useState<
    EnhancedProject[]
  >([]);
  const navigate = useNavigate();

  const userDefaultOrgId = user?.data?.default_organization;
  const organization = useData().organizations?.find(
    (org) => org.id === userDefaultOrgId
  );

  useEffect(() => {
    if (projects && locales) {
      const enhancedProjects = projects.map((project) => {
        const locale = locales.find((locale) => locale.id === project.localeId);
        return { ...project, localeName: locale?.name || "Unknown" };
      }) as EnhancedProject[];

      setProjectWithLocales(enhancedProjects);
    }
  }, [projects, locales]);

  const userProjects = projectWithLocales.filter(
    (project) => project.userId === user.uid
  );

  const handleDeleteProject = async (projectId: string) => {
    if (organization) {
      try {
        const projectRef = doc(
          db,
          `organizations/${organization.id}/projects`,
          projectId
        );
        await deleteDoc(projectRef);
        console.log("Document deleted with ID: ", projectId);

        setProjectWithLocales((prevProjects) =>
          prevProjects.filter((project) => project.id !== projectId)
        );
      } catch (e) {
        console.error("Error deleting document: ", e);
      }
    }
  };

  return (
    <div style={{ paddingLeft: 250 }} className="mt-[16px] mx-4">
      <h2 className="font-bold text-cprimary">Projects</h2>
      <hr style={{ border: "1px rgb(255 29 127) solid mt-2" }} />
      <Table hover>
        <thead>
          <tr>
            <th className="w-[3%]">#</th>
            <th className="w-[10%]">Project Name</th>
            <th className="w-[13%]">Progress</th>
            <th className="w-[1%]"></th>
            <th className="w-[10%]">
              <span className="ml-24">Actions</span>
            </th>
            {/* Add column for hazard icon */}
          </tr>
        </thead>
        <tbody>
          {userProjects.map((project, index) => {
            return (
              <tr
                key={project.id}
                onClick={() => navigate(`/manager/projects/${project.id}`)}
                className="cursor-pointer"
              >
                <td className="w-[3%]">{index + 1}</td>
                <td>{project.name}</td>
                <td>
                  <div className="mt-[6px]">
                    <ProgressBar
                      now={calculateProgress(project.pipeline)}
                      label={`${calculateProgress(project.pipeline).toFixed(
                        2
                      )}%`}
                      variant={getProgressBarVariant(
                        calculateProgress(project.pipeline)
                      )}
                      className="flex-grow-1 me-2"
                    />
                  </div>
                </td>
                <td>
                  <div className="mt-[2px]">
                    {getHazardIcon(project.pipeline) && (
                      <MdWarning size={24} color="red" />
                    )}
                  </div>
                </td>

                <td>
                  <div className="ml-24">
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteProject(project.id);
                      }}
                      className="mr-2 bg-red-400 hover:bg-red-500"
                    >
                      <AiFillDelete size={15} />
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
