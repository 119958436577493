import { RiHandCoinFill } from "react-icons/ri";
import { useAuth } from "@Contexts/AuthContext";
import { useData } from "@Contexts/DataContext";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  FloatingLabel,
  FormControl,
  Modal,
  Offcanvas,
  Table,
} from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import Search from "@Components/Search";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "@Config/firebase";
import { AiFillDelete } from "react-icons/ai";
import PaymentForm from "@Components/PaymentForm";
import axios from "axios";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

export default function OrganizationPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { organizations, users, packages, invitations, retails } = useData();
  const { user } = useAuth();

  const [organization, setOrganization] = useState(
    organizations.filter((org) => org.id === id)?.[0]
  );
  const [member, setMember] = useState(
    organizations
      .filter((org) => org.id === id)?.[0]
      ?.members?.filter?.((mem: any) => mem.uid === user.uid)?.[0]
  );
  const [show, setShow] = useState(false);
  const [selectedMember, setSelectedMember] = useState<any>(null);

  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const userDefaultOrgId = user?.data?.default_organization;
  const userOrganization = organizations.find(
    (org) => org.id === userDefaultOrgId
  );
  const retailMap = retails.reduce((map, retail) => {
    map[retail.id] = retail;
    return map;
  }, {});
  const filteredRetails = userOrganization?.retails?.map(
    (retail: any) => retailMap[retail]
  );
  const hasFilteredResults = filteredRetails?.length > 0;

  useEffect(() => {
    setOrganization(organizations.filter((org) => org.id === id)?.[0]);
    setMember(
      organizations
        .filter((org) => org.id === id)?.[0]
        ?.members?.filter?.((mem: any) => mem.uid === user.uid)?.[0]
    );
  }, [organizations, show, selectedMember]);

  if (!organization) {
    navigate("/manager/organizations");
    return <>Redirecting...</>;
  }

  return (
    <>
      <div style={{ paddingLeft: 250 }} className="mt-4 mx-4">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="font-bold text-cprimary">{organization.name}</h2>
          </div>
        </div>
        <div className="my-3" />
        <div className="w-full flex bg-csecondary p-3 rounded-xl bg-opacity-40 gap-3">
          <div className="w-3/6 mr-4">
            <div className="h-full">
              <h4 className="text-cprimary font-bold">Transactions</h4>
              <hr style={{ border: "1px rgb(255 29 127) solid" }} />
              {organization.transactions.length ? (
                <div className="h-[310px] overflow-y-scroll">
                  <Table size="sm">
                    <tbody>
                      {organization.transactions
                        .filter((transaction: any) => {
                          if (member?.role === "owner") {
                            return true;
                          } else return transaction.user === user.uid;
                        })
                        .slice(0, 50)
                        .map((transaction: any) => ({
                          ...transaction,
                          timestamp: new Timestamp(
                            transaction.timestamp.seconds,
                            transaction.timestamp.nanoseconds
                          ).toDate(),
                        }))
                        .sort(
                          (a: any, b: any) =>
                            (b.timestamp as any) - (a.timestamp as any)
                        )
                        .map((transaction: any) => (
                          <tr>
                            <td style={{ background: "transparent" }}>
                              {transaction.description}
                            </td>
                            <td style={{ background: "transparent" }}>
                              {users.filter(
                                (user) => user.id === transaction.user
                              )?.[0]?.firstName
                                ? users.filter(
                                    (user) => user.id === transaction.user
                                  )?.[0]?.firstName +
                                  " " +
                                  users.filter(
                                    (user) => user.id === transaction.user
                                  )?.[0]?.lastName
                                : users.filter(
                                    (user) => user.id === transaction.user
                                  )?.[0]?.email}
                            </td>
                            <td
                              style={{
                                background: "transparent",
                                color:
                                  transaction.type === "INCOMING"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {transaction.type === "INCOMING" ? "+" : "-"}
                              {transaction.amount}
                            </td>
                            <td
                              style={{
                                background: "transparent",
                                opacity: 0.6,
                              }}
                              className="text-xs text-right w-[10px] whitespace-nowrap"
                            >
                              {timeAgo.format(transaction.timestamp)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center opacity-80">
                  You don't have any transactions.
                </div>
              )}
            </div>
          </div>
          <div className="w-2/6 h-[40vh] flex flex-col gap-3">
            <div
              className={`w-full h-1/2 rounded-xl border-3 border-blue-500 text-blue-500 bg-blue-50 p-3 ${
                member?.role !== "owner" &&
                "blur-md select-none pointer-events-none"
              }`}
            >
              <h6 className="font-black mb-3 underline">
                Unallocated Credits:
              </h6>
              <h1 className="font-black text-[3.3vw]">
                {organization.credits} Credits
              </h1>
              <h5 className="font-black mt-[-7px] text-[1vw]">remaining</h5>
            </div>
            <div className="w-full h-1/2 rounded-xl border-3 border-green-500 text-green-500 bg-green-50 p-3">
              <h6 className="font-black mb-3 underline">Allocated Credits:</h6>
              <h1 className="font-black text-[3.3vw]">
                {organization?.members?.length &&
                  organization?.members
                    ?.filter((mem: any) => {
                      if (member.role === "owner") {
                        return true;
                      } else return mem.uid === user.uid;
                    })
                    .map((mem: any) => mem.credits || 0)
                    .reduce((a: any, b: any) => a + b)}{" "}
                Credits
              </h1>
              <h5 className="font-black mt-[-7px] text-[1vw]">remaining</h5>
            </div>
          </div>
          <div
            className={`w-1/6 h-[40vh] border-3 border-yellow-500 text-yellow-500 bg-yellow-50 rounded-xl p-3 ${
              member.role !== "owner" &&
              "blur-md select-none pointer-events-none"
            }`}
          >
            <div className="flex flex-col justify-between h-full">
              <div>
                <h5 className="font-black text-[1.1vw]">Buy More Credits:</h5>
                <div
                  className="my-3"
                  style={{ border: "1px rgba(234, 179, 8, 0.4) solid" }}
                />
                <div className="flex flex-col gap-2">
                  {packages
                    .sort((a, b) => a.credits - b.credits)
                    .map((packageObj) => (
                      <>
                        <Button
                          className="w-full"
                          style={{
                            background: "rgb(234 179 8)",
                            border: "none",
                          }}
                          onClick={() => setSelectedPackage(packageObj.id)}
                        >
                          {packageObj.credits} Credits @ $
                          {packageObj.amount / 100}
                        </Button>
                      </>
                    ))}
                </div>
              </div>
              <div>
                <div
                  className="my-2"
                  style={{ border: "1px rgba(234, 179, 8, 0.4) solid" }}
                />
                <Button
                  className="w-full"
                  style={{
                    background: "transparent",
                    border: "2px rgb(234 179 8) solid",
                    color: "rgb(234 179 8)",
                    fontWeight: "bold",
                  }}
                >
                  Enterprise
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={`w-full mt-4 ${member.role !== "owner" && "hidden"}`}>
          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-bold text-cprimary">Members</h3>
            </div>
            <Button
              id="theme-button"
              size="sm"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                marginTop: -5,
              }}
              onClick={() => setShow(true)}
            >
              <IoMdAdd /> Invite Member
            </Button>
          </div>
          <hr
            className="mt-[4px]"
            style={{ border: "1px rgb(255 29 127) solid" }}
          />
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Credits</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {organization?.members?.map((member: any) => (
                <tr key={member.uid}>
                  <td>
                    {users.filter((user) => user.id === member.uid)?.[0]
                      ?.firstName
                      ? users.filter((user) => user.id === member.uid)?.[0]
                          ?.firstName +
                        " " +
                        users.filter((user) => user.id === member.uid)?.[0]
                          ?.lastName
                      : users.filter((user) => user.id === member.uid)?.[0]
                          ?.email}
                    {user.uid === member.uid ? " (You)" : ""}
                  </td>
                  <td>{member.role}</td>
                  <td>{member.credits === undefined ? "" : member.credits}</td>
                  <td>
                    {member.role === "member" && (
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() =>
                          deleteDoc(
                            doc(
                              db,
                              `organizations/${organization.id}/members/${member.uid}`
                            )
                          )
                        }
                      >
                        <AiFillDelete />
                      </Button>
                    )}
                    {member.role === "member" && (
                      <Button
                        size="sm"
                        style={{ marginLeft: 4 }}
                        onClick={() => setSelectedMember(member.uid)}
                      >
                        <RiHandCoinFill />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          
          {invitations.filter((invitation) => invitation.organization === id)
            .length ? (
            <>
              <h5>Pending Invites</h5>
              <Table>
                <thead>
                  <tr>
                    <th>Invited</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {invitations
                    .filter((invitation) => invitation.organization === id)
                    ?.map((invitation: any) => (
                      <tr key={member.uid}>
                        <td>{invitation.email}</td>
                        <td>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() =>
                              deleteDoc(doc(db, `invitations/${invitation.id}`))
                            }
                          >
                            <AiFillDelete />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          ) : (
            <></>
          )}

          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-bold text-cprimary">Datasets</h3>
            </div>
            <Button
              id="theme-button"
              size="sm"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                marginTop: -5,
              }}
              onClick={() => navigate("/manager/market")}
            >
              {hasFilteredResults ? "+ Buy More" : "Purchase Dataset"}
            </Button>
          </div>
          <hr
            className="mt-[4px]"
            style={{ border: "1px rgb(255 29 127) solid" }}
          />
          <Table>
            {hasFilteredResults ? (
              <>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Locations</th>
                  </tr>
                </thead>
                <tbody>
                  {userOrganization?.retails?.map((retailId: string) => {
                    const retail = retails.find(
                      (retail: any) => retail.id === retailId
                    );
                    return (
                      <tr key={retailId}>
                        <td>{retail?.name}</td>
                        <td>{retail?.locations}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    No Dataset Available
                  </td>
                </tr>
              </tbody>
            )}
          </Table>


        </div>
      </div>
      {show && (
        <InviteModal
          show={show}
          onHide={() => setShow(false)}
          organization={organization}
        />
      )}
      <AllocateModal
        selectedMember={selectedMember}
        onHide={() => setSelectedMember(null)}
        organization={organization}
      />

      <Checkout
        selectedPackage={selectedPackage}
        onHide={() => setSelectedPackage(null)}
        organization={organization}
      />
    </>
  );
}

const Checkout = ({ selectedPackage, onHide, organization }: any) => {
  const { packages } = useData();
  const [intent, setIntent] = useState<any>();
  const [err, setErr] = useState("");

  const { user } = useAuth();
  const fetchSecret = async () => {
    const response = await axios.post(
      `${import.meta.env.VITE_API_URL}/payments/create`,
      {
        package: packages.filter((pkg) => pkg.id === selectedPackage)[0].id,
        type: "credits",
      }
    );

    if (response.status === 200) {
      setIntent(response.data.intent);
    }
  };

  useEffect(() => {
    if (selectedPackage) {
      fetchSecret().catch(() => {
        setErr("There was a problem while creating a payment session for you.");
      });
    }
  }, [selectedPackage]);

  return (
    <Offcanvas show={Boolean(selectedPackage)} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title
          style={{ color: "rgb(255 29 127)", fontWeight: "bold", fontSize: 24 }}
        >
          Checkout
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h5 className="font-bold">Includes:</h5>
          {selectedPackage && (
            <p>
              x{packages.filter((pkg) => pkg.id === selectedPackage)[0].credits}{" "}
              Credits
            </p>
          )}
        </div>
        <div>
          {err && <Alert variant="danger">{err}</Alert>}
          {intent && selectedPackage && (
            <PaymentForm
              onSubmit={async () => {
                await updateDoc(doc(db, `organizations/${organization.id}`), {
                  credits:
                    organization.credits +
                    packages.filter((pkg) => pkg.id === selectedPackage)[0]
                      .credits,
                  transactions: [
                    ...organization.transactions,
                    {
                      user: user.uid,
                      amount: packages.filter(
                        (pkg) => pkg.id === selectedPackage
                      )[0].credits,
                      timestamp: new Date(),
                      type: "INCOMING",
                      description: "Credits Purchased",
                    },
                  ],
                });

                await axios
                  .post(
                    import.meta.env.VITE_API_URL +
                      "/notifications/credits/purchased",
                    {
                      sender: user?.data?.firstName
                        ? `${user.data.firstName} ${user?.data?.lastName}`
                        : user.email.split("@")[0],
                      receiver: user?.data?.email,
                      organization: organization.name,
                      price:
                        packages.filter((pkg) => pkg.id === selectedPackage)[0]
                          .amount / 100,
                      credits: packages.filter(
                        (pkg) => pkg.id === selectedPackage
                      )[0].credits,
                    }
                  )
                  .catch((err) => {
                    console.log(err);
                    err.response
                      ? setErr(
                          `${err.response.status}: ${err.response.data.message}`
                        )
                      : setErr(`Internal server error occurred.`);
                  });
                onHide();
              }}
              intent={intent}
              amount={selectedPackage.amount}
            />
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const InviteModal = ({ show, onHide, organization }: any) => {
  const { users } = useData();
  const { user } = useAuth();
  const [addingUsers, setAddingUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [externalInvite, setExternalInvite] = useState(false);
  const [err, setErr] = useState("");

  useEffect(() => {
    setAddingUsers([]);
  }, []);

  useEffect(() => {
    if (err) setTimeout(() => setErr(""), 30000);
  }, [err]);

  useEffect(() => {
    setExternalInvite(
      searchText.includes("@") &&
        searchText.includes(".") &&
        users.filter((lUser: any) =>
          lUser.email.toLowerCase().includes(searchText.toLowerCase())
        ).length === 0
    );
  }, [searchText]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Invite Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Search
          list={users.filter((lUser) => lUser.email !== user.email)}
          addingItems={addingUsers}
          setAddingItems={setAddingUsers}
          searchingProperty={"email"}
          placeholder={"Add Users"}
          onSearchTextChange={setSearchText}
        />

        {err && (
          <Alert variant="danger" className="mt-2">
            {err}
          </Alert>
        )}
        {externalInvite && (
          <Alert className="mt-2">
            This user does not seem to be on the platform, We are going to send
            an invitation to them
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Close
        </Button>
        <Button
          disabled={externalInvite ? false : !addingUsers.length}
          id="theme-button"
          onClick={async () => {
            if (externalInvite) {
              const invitationRef = doc(collection(db, `invitations`));
              await axios
                .post(import.meta.env.VITE_API_URL + "/notifications/invite", {
                  sender: user?.data?.firstName
                    ? `${user.data.firstName} ${user?.data?.lastName}`
                    : user.email.split("@")[0],
                  receiver: searchText,
                  organization: organization.name,
                  invitation: invitationRef.id,
                })
                .catch((err) => {
                  console.log(err);
                  err.response
                    ? setErr(
                        `${err.response.status}: ${err.response.data.message}`
                      )
                    : setErr(`Internal server error occurred.`);
                });

              await setDoc(invitationRef, {
                email: searchText,
                organization: organization.id,
              });
              onHide();
            } else {
              if (addingUsers.length) {
                await Promise.all(
                  addingUsers.map(
                    async (addingUser: any) =>
                      await setDoc(
                        doc(
                          db,
                          `organizations/${organization.id}/members/${addingUser.id}`
                        ),
                        { uid: addingUser.id, role: "member", credits: 0 }
                      )
                  )
                );
              }
              onHide();
            }
          }}
        >
          {externalInvite ? "Invite" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AllocateModal = ({ selectedMember, onHide, organization }: any) => {
  const [amount, setAmount] = useState(0);
  const { users } = useData();
  const [err, setErr] = useState("");
  const { user } = useAuth();
  useEffect(() => {
    if (err) setTimeout(() => setErr(""), 30000);
  }, [err]);

  const selectedMemberDetails = users.find(
    (user) => user.id === selectedMember
  );

  return (
    <Modal show={Boolean(selectedMember)} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Allocate Credits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel label="Amount">
          <FormControl
            type="number"
            value={amount}
            onChange={(e) => {
              if (Number(e.target.value) > organization.credits) {
                setAmount(organization.credits);
              } else {
                setAmount(Number(e.target.value));
              }
            }}
            max={organization.credits}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Close
        </Button>
        <Button
          id="theme-button"
          onClick={async () => {
            const member = organization?.members?.filter(
              (mem: any) => mem.uid === selectedMember
            )?.[0];
            await updateDoc(doc(db, `organizations/${organization.id}`), {
              credits: organization.credits - amount,
              transactions: [
                ...organization.transactions,
                {
                  user: selectedMember,
                  amount: amount,
                  timestamp: new Date(),
                  type: "OUTGOING",
                  description: "Allocated to member",
                },
              ],
            });
            await updateDoc(
              doc(
                db,
                `organizations/${organization.id}/members/${selectedMember}`
              ),
              {
                credits: Number(member.credits) + amount,
              }
            );

            await axios
              .post(
                import.meta.env.VITE_API_URL +
                  "/notifications/credits/assigned",
                {
                  sender: user?.data?.firstName
                    ? `${user.data.firstName} ${user?.data?.lastName}`
                    : user.email.split("@")[0],

                  member: `${selectedMemberDetails.firstName} ${selectedMemberDetails.lastName}`,
                  receiver: selectedMemberDetails?.email,
                  organization: organization.name,
                  credits: amount,
                }
              )
              .catch((err) => {
                console.log(err);
                err.response
                  ? setErr(
                      `${err.response.status}: ${err.response.data.message}`
                    )
                  : setErr(`Internal server error occurred.`);
              });

            onHide();
          }}
        >
          Allocate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
