import { db } from "@Config/firebase";
import { doc, updateDoc } from "@firebase/firestore";

const setter = async (value: string, uid: string) => {
  return await updateDoc(doc(db, `users/${uid}`), {
    default_organization: value,
  });
};

export default {
  remove: () => localStorage.removeItem("default-organization"),
  set: setter,
  get: () => localStorage.getItem("default-organization"),
};
