// import LOGO from "@Assets/logo.png";

export default function PageLoading({ text, update }: any) {
  if (update) {
    return (
      <div className="w-screen h-screen absolute top-0 left-0 bg-transparent cursor-progress"></div>
    );
  }
  return (
    <div className="w-screen h-screen absolute top-0 left-0 grid place-items-center">
      <div className="flex flex-col gap-4 items-center justify-center text-center">
        <img src={"/favicon.png"} width={84} className=" animate-bounce" />
        {text}
      </div>
    </div>
  );
}
