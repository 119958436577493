import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillShop } from "react-icons/ai";
import { RxCornerTopRight } from "react-icons/rx";
import { RxCorners } from "react-icons/rx";
import { FaParking } from "react-icons/fa";
import { BsBuildings } from "react-icons/bs";
import { Button, Placeholder } from "react-bootstrap";

import { GoogleMap, Marker, InfoBox } from "@react-google-maps/api";
import { useData } from "@Contexts/DataContext";
import { useAuth } from "@Contexts/AuthContext";
import { useEffect, useState } from "react";

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default function Search({ isLoaded }: any) {
  const [center, setCenter] = useState({ lat: 18.8975617, lng: -96.9385916 });
  const [mapShow, setMapShow] = useState(false);

  const [selectedLocale, setSelectedLocale] = useState<any>(null);

  const { locales } = useData();
  const { user } = useAuth();

  useEffect(() => {
    if (document.querySelector(".fixed-parent")?.clientWidth && isLoaded)
      setMapShow(true);
  }, [document.querySelector(".fixed-parent"), isLoaded]);

  useEffect(() => {
    const listener = (e: any) => {
      if (e.key === "Escape") {
        setSelectedLocale(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div
      style={{
        paddingLeft: 250,
      }}
    >
      <div className="mx-4 mt-4 w-[49%]">
        <h2 className="text-cprimary font-bold">Local Search</h2>
        <hr style={{ border: "1px rgb(255 29 127) solid" }} />
      </div>
      {
        <div className="flex flex-col justify-between h-[145px]">
          <div
            style={{ marginLeft: 7 }}
            className="w-[49%] grid grid-cols-2 gap-2 rounded-xl"
          >
            {locales
              .filter((locale) => {
                if (
                  locale.visibility &&
                  (locale.visibility.includes(
                    user?.data?.default_organization
                  ) ||
                    locale.visibility.length === 0)
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((locale, idx) => (
                <div
                  key={idx}
                  className="relative rounded-xl p-3 border flex flex-col"
                  style={{ height: "100%" }} // Ensure full height for the card
                >
                  <Image
                    src={locale.images[0]}
                    height={200}
                    className="max-h-[200px] min-h-[120px] rounded min-w-[100%] drop-shadow-2xl"
                  />
                  <br />
                  <div className="flex flex-col justify-between flex-grow">
                    <div>
                      <h3
                        className="text-xl mb-2"
                        style={{ minHeight: "2.8em" }}
                      >
                        {locale.location.address}
                      </h3>
                      <div
                        className="flex gap-2 flex-wrap opacity-70"
                        style={{ minHeight: "1em" }}
                      >
                        <h3 className="flex gap-1 items-center h-1 text-xs">
                          <RxCorners />
                          <span>
                            {locale.sqMeter} m<sup>2</sup>
                          </span>
                        </h3>
                        <h3 className="flex gap-1 items-center h-1 text-xs">
                          <BsBuildings />
                          {locale.floors} Floor(s)
                        </h3>
                        {locale.haveParking && (
                          <h3 className="flex gap-1 items-center h-1 text-xs">
                            <FaParking />
                            Parking
                          </h3>
                        )}
                        {locale.isCorner && (
                          <h3 className="flex gap-1 items-center h-1 text-xs">
                            <RxCornerTopRight />
                            Corner
                          </h3>
                        )}
                        {locale.commercial && (
                          <h3 className="flex gap-1 items-center h-1 text-xs">
                            <AiFillShop />
                            Commercial
                          </h3>
                        )}
                      </div>
                    </div>
                    <div
                      className="flex justify-between w-full items-center mt-auto"
                      style={{ minHeight: "2.5em" }}
                    >
                      <h1 className="text-2xl font-bold">
                        {USDollar.format(locale.price)}
                      </h1>
                      <Button
                        className="flex items-center justify-center bg-yellow-400 text-white w-[50px] h-[50px] rounded-xl cursor-pointer p-0"
                        id="theme-button"
                        onClick={() =>
                          setCenter({
                            lat: locale.location.latitude,
                            lng: locale.location.longitude,
                          })
                        }
                      >
                        <FaMapMarkerAlt className="ml-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="fixed-parent relative w-1/2 h-[100vh]">
            {mapShow && (
              <div
                style={{
                  width:
                    (document.querySelector(".fixed-parent")?.clientWidth ||
                      0) - 16,
                }}
                className="h-[calc(100vh-32px)] top-4 right-4 fixed"
                // className="h-screen top-0 fixed"
              >
                <GoogleMap
                  zoom={13}
                  center={center}
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 14,
                    marginBottom: 18,
                  }}
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                  }}
                >
                  {locales
                    .filter((locale) => {
                      // Check if the locale is visible to the current organization or to all organizations
                      return (
                        locale.visibility &&
                        (locale.visibility.includes(
                          user?.data?.default_organization
                        ) ||
                          locale.visibility.length === 0)
                      );
                    })
                    .map((locale, idx) => (
                      <Marker
                        key={idx}
                        position={{
                          lat: locale.location.latitude,
                          lng: locale.location.longitude,
                        }}
                        icon={{
                          url: "/shop.svg",
                        }}
                        onClick={() => {
                          setSelectedLocale(locale);
                        }}
                      >
                        {selectedLocale === locale && (
                          <InfoBox>
                            <>
                              <div className="relative max-w-[300px] rounded-xl p-3 border bg-white">
                                <div
                                  className="absolute top-0 right-2 text-lg cursor-pointer"
                                  onClick={() => setSelectedLocale(null)}
                                >
                                  ✕
                                </div>
                                <img
                                  src={selectedLocale.images[0]}
                                  className="max-h-[150px] mt-[10px] min-h-[120px] rounded min-w-[90%] drop-shadow-2xl "
                                />
                                <br />
                                <div className="flex flex-col justify-between">
                                  <div>
                                    <h3 className="text-xl mb-2">
                                      {selectedLocale.location.address}
                                    </h3>
                                    <div className="flex gap-2 flex-wrap opacity-70">
                                      <h3 className="flex gap-1 items-center h-1 text-xs">
                                        <RxCorners />
                                        <span>
                                          {selectedLocale.sqMeter} m<sup>2</sup>
                                        </span>
                                      </h3>
                                      <h3 className="flex gap-1 items-center h-1 text-xs">
                                        <BsBuildings />
                                        {selectedLocale.floors} Floor(s)
                                      </h3>
                                      {selectedLocale.haveParking && (
                                        <h3 className="flex gap-1 items-center h-1 text-xs">
                                          <FaParking />
                                          Parking
                                        </h3>
                                      )}
                                      {selectedLocale.isCorner && (
                                        <h3 className="flex gap-1 items-center h-1 text-xs">
                                          <RxCornerTopRight />
                                          Corner
                                        </h3>
                                      )}
                                      {selectedLocale.commercial && (
                                        <h3 className="flex gap-1 items-center h-1 text-xs">
                                          <AiFillShop />
                                          Commercial
                                        </h3>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex justify-between w-full">
                                    <h1 className="text-2xl font-bold translate-y-[5px]">
                                      {USDollar.format(selectedLocale.price)}
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </>
                          </InfoBox>
                        )}
                      </Marker>
                    ))}
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
}

const Image = ({ ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div style={{ height: rest.height }} className="relative">
      {!loaded && (
        <Placeholder
          style={{
            width: "100%",
            height: rest.height,
            position: "absolute",
          }}
          {...rest}
        />
      )}
      <img
        style={{ opacity: loaded ? 1 : 0 }}
        onLoad={() => setLoaded(true)}
        {...rest}
      />
    </div>
  );
};
