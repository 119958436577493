import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { db, storage } from "@Config/firebase";
import PageLoading from "../components/PageLoading";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useAuth } from "./AuthContext";
// @ts-expect-error
import LocationsWorker from "@Workers/locations.worker.js";
import WorkerFactory from "@Utility/WorkerFactory";

const DataContext = createContext<DataContextProps>({} as DataContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }: any) {
  const [loading, setLoading] = useState(true);
  const [locales, setLocales] = useState<any[]>([]);
  const [retails, setRetails] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [invitations, setInvitations] = useState<any[]>([]);
  const [packages, setPackages] = useState<Package[]>([]);
  const [allOrganizations, setAllOrganizations] = useState<any[]>([]);
  const [place, setPlaces] = useState<any[]>([]);
  const [templates, setTemplates] = useState<any[]>([]); // New state for templates
  const [projects, setProjects] = useState<any[]>([]); // State for projects

  const { user } = useAuth();

  const getTemplates = async () => {
    onSnapshot(collection(db, "templates"), (snapshot) => {
      setTemplates(
        snapshot.docs.map((document) => ({
          ...document.data(),
          id: document.id,
        }))
      );
    });
  };

  const getPackages = async () => {
    onSnapshot(collection(db, `packages`), (snapshot) => {
      setPackages(
        snapshot.docs.map((document) => ({
          ...document.data(),
          id: document.id,
        })) as any as Package[]
      );
    });
  };

  const getCategories = async () => {
    onSnapshot(collection(db, `database-categories`), (snapshot) => {
      setCategories(
        snapshot.docs.map((document) => ({
          ...document.data(),
          id: document.id,
        }))
      );
    });
  };

  const getLocales = async () => {
    onSnapshot(collection(db, "locales"), (snapshot) => {
      setLocales(
        snapshot.docs.map((document) => ({
          ...document.data(),
          id: document.id,
        }))
      );
    });
  };

  const getRetail = async () => {
    onSnapshot(collection(db, "retail"), async (snapshot) => {
      setRetails(
        await Promise.all(
          snapshot.docs.map(async (document) => ({
            ...document.data(),
            id: document.id,
            locations: (
              await getCountFromServer(
                collection(db, `retail/${document.id}/data`)
              )
            ).data().count,
          }))
        )
      );
    });
  };

  const getUsers = async () => {
    onSnapshot(collection(db, "users"), (snapshot) => {
      setUsers(
        snapshot.docs.map((document) => ({
          ...document.data(),
          id: document.id,
        }))
      );
    });
  };

  const getInvitations = async () => {
    onSnapshot(collection(db, "invitations"), (snapshot) => {
      setInvitations(
        snapshot.docs.map((document) => ({
          ...document.data(),
          id: document.id,
        }))
      );
    });
  };

  const getAllOrganizations = async () => {
    onSnapshot(collection(db, "organizations"), (snapshot) => {
      const orgs = snapshot.docs.map((document) => ({
        ...document.data(),
        id: document.id,
      }));
      setAllOrganizations(orgs);
      getOrganizationProjects(orgs);
    });
  };

  const getOrganizationProjects = (organizations: any[]) => {
    organizations.forEach((organization) => {
      const projectsRef = collection(
        db,
        `organizations/${organization.id}/projects`
      );
      onSnapshot(projectsRef, (snapshot) => {
        const newProjects = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          organizationId: organization.id,
        }));

        setProjects((prevProjects) => [
          ...prevProjects.filter((p) => p.organizationId !== organization.id),
          ...newProjects,
        ]);
      });
    });
  };

  const getLocations = async () => {
    let worker: Worker | null = null;
    if (worker === null && window.Worker) {
      worker = new WorkerFactory(LocationsWorker) as Worker;
      worker.postMessage({
        retails,
      });

      worker.onmessage = (msg) => {
        const data = msg.data;

        setLocations(data);
        if (!data.length)
          alert("An error occurred while fetching retail locations.");

        if (worker) worker.terminate();
      };
    }
  };

  const getOrganizations = async () => {
    onSnapshot(query(collection(db, "organizations")), async (snapshot) => {
      const organizations = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      getOrganizationMembers(organizations);
    });
  };

  const getOrganizationMembers = async (allOrganizations: any[]) => {
    const memberOrgs = (
      await Promise.all(
        allOrganizations.map(async (org) => {
          const document = await getDoc(
            doc(db, `organizations/${org.id}/members/${user.uid}`)
          );
          if (document.exists()) {
            return org;
          } else {
            return false;
          }
        })
      )
    ).filter((mem) => mem);

    memberOrgs.map(async (org) => {
      onSnapshot(
        query(collection(db, `organizations/${org?.id}/members`)),
        async (snapshot) => {
          const members = snapshot.docs.map((doc) => doc.data());
          memberOrgs.filter(
            (organization) => organization?.id === org?.id
          )[0].members = members;
          setOrganizations(memberOrgs);
        }
      );
    });
  };

  const deductCredit = async (organization: any, uid: string) => {
    const member = organization.members.filter(
      (mem: any) => mem.uid === uid
    )?.[0];
    if (member?.role === "owner") {
      return await updateDoc(doc(db, `organizations/${organization.id}`), {
        credits: organization.credits - 1,
        transactions: [
          ...organization.transactions,
          {
            user: uid,
            amount: 1,
            timestamp: new Date(),
            type: "OUTGOING",
            description: "Explore Page",
          },
        ],
      });
    } else if (member?.role === "member") {
      await updateDoc(
        doc(db, `organizations/${organization.id}/members/${uid}`),
        { credits: Number(member.credits) - 1 }
      );
      return await updateDoc(doc(db, `organizations/${organization.id}`), {
        transactions: [
          ...organization.transactions,
          {
            user: uid,
            amount: 1,
            timestamp: new Date(),
            type: "OUTGOING",
            description: "Explore Page",
          },
        ],
      });
    }
  };

  const uploadFile = async (file: File, path: string) => {
    const fileRef = ref(storage, path);
    const fileSnapshot = await uploadBytes(fileRef, file);
    const downloadURL = await getDownloadURL(fileSnapshot.ref);
    return downloadURL;
  };

  const getPlaces = async () => {
    onSnapshot(collection(db, "places"), (snapshot) => {
      setPlaces(
        snapshot.docs.map((document) => ({
          ...document.data(),
          id: document.id,
        }))
      );
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        getInvitations();
        getAllOrganizations();

        if (user) {
          setLoading(true);
          await Promise.all([
            getLocales(),
            getRetail(),
            getCategories(),
            getUsers(),
            getPackages(),
            getOrganizations(),
            getPlaces(),
            getTemplates(),
          ]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    if (retails.length) {
      (async () => {
        await getLocations();
      })();
    }
  }, [retails]);

  useEffect(() => {
    if (user) {
      if (
        locales.length &&
        retails.length &&
        locations.length &&
        allOrganizations.length &&
        organizations.length &&
        place.length &&
        templates.length &&
        projects.length
      ) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [locales, retails, locations, organizations, place, templates, user]);

  const isManager = users.find(
    (u) => u.id === user.uid && u.role === "manager"
  );
  const isAdmin = users.find((u) => u.id === user.uid && u.role === "admin");

  const value: DataContextProps = {
    locales,
    retails,
    locations,
    categories,
    organizations,
    users,
    uploadFile,
    deductCredit,
    packages,
    invitations,
    allOrganizations,
    getOrganizations,
    place,
    templates,
    projects,
  };

  return (
    <DataContext.Provider value={value}>
      {(isManager ? organizations.length > 0 : true) &&
      (isAdmin ? retails.length > 0 : true) &&
      !loading ? (
        children
      ) : (
        <PageLoading />
      )}
    </DataContext.Provider>
  );
}
