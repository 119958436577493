import { useEffect, useState } from "react";
import { Form, FloatingLabel, Button, Alert } from "react-bootstrap";
import { useAuth } from "@Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import BioretailPNG from "@Assets/images/bioretail.png";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("broker");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const { signup, user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  return (
    <div className="bg-csecondary h-[calc(100vh-70px)] pt-[10%] flex-auto">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5" style={{ textAlign: "center" }}>
            <div className="form-signin">
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  await signup(
                    { email, password, firstName, lastName },
                    userType
                  ).catch((err) =>
                    setErr(
                      err.message
                        .replace("Firebase", "")
                        .replace(":", "")
                        .trim()
                    )
                  );
                  setLoading(false);
                }}
                action=""
                id="form-signup"
              >
                <h2 className="font-bold mb-4">SIGN UP</h2>
                <div className="flex gap-2">
                  <FloatingLabel label="First Name" className="mb-2 w-full">
                    <Form.Control
                      className="form-control"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FloatingLabel>
                  <FloatingLabel label="Last Name" className="mb-2 w-full">
                    <Form.Control
                      className="form-control"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
                <FloatingLabel label="Email" className="mb-2">
                  <Form.Control
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>

                <FloatingLabel label="Password" className="mb-3">
                  <Form.Control
                    type="password"
                    className="form-control"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>

                <p className="text-left">Selecciona tipo de usuario:</p>
                <Form.Group className="text-left mt-[-12px]">
                  <Form.Check
                    type="radio"
                    inline
                    label="Broker"
                    checked={userType === "broker"}
                    onClick={() => setUserType("broker")}
                  />
                  <Form.Check
                    type="radio"
                    inline
                    label="Manager"
                    checked={userType === "manager"}
                    onClick={() => setUserType("manager")}
                  />
                </Form.Group>
                <hr />
                {err && (
                  <>
                    <Alert variant="danger" style={{ textAlign: "left" }}>
                      {err}
                    </Alert>
                    <hr />
                  </>
                )}
                <Button
                  className="w-100"
                  id="theme-button"
                  size="lg"
                  type="submit"
                  disabled={
                    loading ||
                    !email ||
                    !password ||
                    !userType ||
                    !firstName ||
                    !lastName
                  }
                >
                  Registrate
                </Button>
                <div className="flex items-center justify-center mt-5 mb-3">
  <p className="text-muted  mr-2">Powered By</p>
  <img style={{marginTop: "-20px"} }src={BioretailPNG} className="w-[5vw] h-full" />
</div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
