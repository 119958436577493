import { Button } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="h-[calc(100vh-70px)] bg-csecondary">
      <div className="container text-center">
        <div
          style={{ fontSize: "4rem", fontWeight: "bold", paddingTop: "15%" }}
        >
          Not Found
        </div>
        <div style={{ fontSize: "2rem", paddingTop: 8 }}>
          The page you are looking for does not exist.
        </div>
        <div
          style={{ marginTop: 24 }}
          className="flex gap-2 items-center justify-center"
        >
          <Button
            id="theme-button-outline"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
            onClick={() => navigate(-1)}
          >
            <AiOutlineLeft />
          </Button>

          <Button id="theme-button" onClick={() => navigate("/")}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
}
