// import React from "react";

export default function AccountManager() {
  return (
    <div
      style={{
        paddingLeft: 250,
      }}
    >
      AccountManager
    </div>
  );
}
