import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FormEvent, useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

export default function PaymentForm({ intent, onSubmit }: any) {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: intent.client_secret,
        appearance: {
          theme: "stripe",
          variables: {
            fontWeightNormal: "500",
            borderRadius: "12px",
            colorPrimary: "#f360a6",
            tabIconSelectedColor: "#fff",
            gridRowSpacing: "16px",
          },
          rules: {
            ".Tab, .Input, .Block, .CheckboxInput, .CodeInput": {
              boxShadow: "0px 3px 10px rgba(18, 42, 66, 0.08)",
            },
            ".Block": {
              borderColor: "transparent",
            },
            ".BlockDivider": {
              backgroundColor: "#ebebeb",
            },
            ".Tab, .Tab:hover, .Tab:focus": {
              border: "0",
            },
            ".Tab--selected, .Tab--selected:hover": {
              backgroundColor: "#f360a6",
              color: "#fff",
            },
          },
        },
      }}
    >
      <Form onSubmit={onSubmit} intent={intent} />
    </Elements>
  );
}

const Form = ({ onSubmit, intent }: any) => {
  const stripe = useStripe();
  const elements = useElements();

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://localesfantastics.com/manager/organizations",
      },
      redirect: "if_required",
    });

    if (result.error) {
      setErr(result.error.message || "");
    } else {
      await onSubmit?.();
    }
    setLoading(false);
  };

  useEffect(() => {
    err &&
      setTimeout(() => {
        setErr("");
      }, 9000);

    return () => clearTimeout(undefined);
  }, [err]);

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="h-[2px] mx-2 mt-3 bg-cprimary" />
      {err ? (
        <Alert variant="danger" className="my-3">
          {err}
        </Alert>
      ) : (
        <h4 className="my-3">Amount: ${intent.amount / 100}</h4>
      )}
      <div className="h-[2px] mx-2 mb-2 bg-cprimary" />
      <Button
        size="lg"
        id="theme-button"
        className="w-100"
        type="submit"
        disabled={!stripe || !elements || loading}
      >
        Pay
      </Button>
    </form>
  );
};
