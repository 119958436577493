import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAYxgcpmkfrrjGA6fJsOu89X_wxT2E4Ec0",
  authDomain: "localesfantasticos.firebaseapp.com",
  databaseURL: "https://localesfantasticos-default-rtdb.firebaseio.com",
  projectId: "localesfantasticos",
  storageBucket: "localesfantasticos.appspot.com",
  messagingSenderId: "85476476931",
  appId: "1:85476476931:web:4884b9436563c62068a636",
  measurementId: "G-YYFJV0P2SB",
};

const firebaseConfigAuth = {
  apiKey: "AIzaSyDYEMDHizFB86v8OiGlbZvjnpNmOsH8JL0",
  authDomain: "bioretail-exchange.firebaseapp.com",
  projectId: "bioretail-exchange",
  storageBucket: "bioretail-exchange.appspot.com",
  messagingSenderId: "401793579814",
  appId: "1:401793579814:web:654dd0bbd3efd3d1bf1fe7"
};

// Initialize Firebase
export const exchange_app = initializeApp(firebaseConfigAuth, "exchange");
export const app = initializeApp(firebaseConfig);
export const database = getDatabase();
export const db = getFirestore();
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const exchange_auth = getAuth(exchange_app);
export const storage = getStorage(app);
