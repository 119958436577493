import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const baseWidth = 50;
const sociowidth = 30;

const Pdf: React.FC<PdfProps> = ({
  isochrone,
  populationData,
  populationToggle,
  placesToggle,
  center,
  refPoly,
  nse,
  demographics,
  places,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSections, setSelectedSections] = useState<{
    [key: string]: string[];
  }>({});

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const toggleContent = (sectionName: string, contentName: string) => {
    setSelectedSections((prevState) => {
      const currentContents = prevState[sectionName] || [];
      if (currentContents.includes(contentName)) {
        return {
          ...prevState,
          [sectionName]: currentContents.filter((item) => item !== contentName),
        };
      } else {
        return {
          ...prevState,
          [sectionName]: [...currentContents, contentName],
        };
      }
    });
  };

  const isContentSelected = (sectionName: string, contentName: string) => {
    return (selectedSections[sectionName] || []).includes(contentName);
  };

  const isTopRetailSelected = isContentSelected("Section 1", "Content 5");
  const allSectionsSelected = () => {
    return (
      isContentSelected("Section 1", "Content 1") &&
      isContentSelected("Section 1", "Content 2") &&
      isContentSelected("Section 1", "Content 3") &&
      isContentSelected("Section 1", "Content 4") &&
      isContentSelected("Section 1", "Content 5")
    );
  };

  const reportDivHeight = allSectionsSelected() ? "2346px" : "1173px";

  const generatePDF = () => {
    const doc = new jsPDF({
      unit: "px",
      format: [595, 842],
    });
    const reportDiv = document.getElementById("report-div");

    if (reportDiv) {
      const options = {
        scale: 2,
        useCORS: true,
        logging: true,
        scrollX: 0,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.scrollHeight,
      };

      html2canvas(reportDiv, options).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.8);
        const imgWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save("report.pdf");
      });
    }
  };

  useEffect(() => {
    if (showModal && isochrone.length === 0) {
      setSelectedSections({});
    } else if (showModal && isochrone.length > 0) {
      setSelectedSections({
        "Section 1": [
          "Content 1",
          "Content 2",
          "Content 3",
          "Content 4",
          "Content 5",
        ],
      });
    }
  }, [showModal, isochrone]);

  useEffect(() => {
    if (showModal && isochrone.length) {
      setSelectedSections({
        "Section 1": [
          "Content 1",
          "Content 2",
          "Content 3",
          "Content 4",
          "Content 5",
        ],
      });
    }
  }, [showModal]);

  return (
    <div>
      <Button
        className="w-full"
        variant="primary"
        onClick={openModal}
        id="theme-button"
        disabled={isochrone.length === 0}
      >
        Generate Report
      </Button>

      <Modal
        show={showModal}
        onHide={closeModal}
        size="xl"
        contentClassName="export-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex min-h-[900px]">
            <div
              id="report-div"
              style={{
                width: "66%",
                height: reportDivHeight,
                padding: "20px",

                overflow: "hidden",
                position: "relative",
                boxShadow:
                  "0 0 10px rgba(0, 0, 0, 0.2), inset 0 0 10px rgba(255, 255, 255, 0.2)",
                backgroundColor: "white",
                border: "1px solid #ccc",
              }}
            >
              {isTopRetailSelected && (
                <div>
                  <div className="absolute top-[1173px] left-0 w-full h-[1px] shadow-[0_0_10px_rgba(0,0,0,0.3)] bg-gray-300 border-b border-gray-300"></div>
                  <div className="absolute top-[1173px] left-0 w-full h-[1px] shadow-[0_0_10px_rgba(0,0,0,0.3)] bg-gray-300 border-b border-gray-300"></div>
                </div>
              )}

              <div id="header">
                <h2 className="text-center mb-4 font-bold text-cprimary">
                  REPORT
                </h2>

                {isochrone.length && <hr />}
              </div>
              {isContentSelected("Section 1", "Content 1") && (
                <div>
                  <div id="map">
                    <MapDisplay
                      isochrone={isochrone}
                      center={center}
                      refPoly={refPoly}
                      populationData={populationData}
                      populationToggle={
                        isContentSelected("Section 1", "Content 2") &&
                        populationToggle
                      }
                    />
                  </div>
                  <div id="populationdata">
                    {isContentSelected("Section 1", "Content 2") &&
                      Boolean(populationData.length) &&
                      populationToggle && (
                        <div className="mt-2 text-black">
                          <span className="font-bold">
                            Total Average Population:
                          </span>{" "}
                          {populationData
                            .reduce((a: any, b: any) => ({
                              ...a,
                              weight: a.weight + b.weight,
                            }))
                            .weight.toFixed(2)}
                        </div>
                      )}
                  </div>
                  <br />
                </div>
              )}

              <div id="Socioeconomics" style={{ marginTop: "-10px" }}>
                {isContentSelected("Section 1", "Content 3") && (
                  <div>
                    <h5 className="text-left mb-6 font-bold">
                      Socioeconomics:
                    </h5>

                    <div style={{ marginTop: "-10px" }}>
                      <DemographicsDisplay
                        nse={nse}
                        demographics={demographics}
                      />
                    </div>
                    <br />
                  </div>
                )}
              </div>

              <div id="Population" style={{ marginTop: "-10px" }}>
                {isContentSelected("Section 1", "Content 4") && (
                  <div style={{ pageBreakAfter: "always" }}>
                    <h5 className="text-left mb-6 font-bold">
                      Population Data:
                    </h5>
                    <div style={{ marginTop: "-10px" }}>
                      <PopulationDisplay demographics={demographics} />
                    </div>
                    <br />
                  </div>
                )}
              </div>
              <div
                id="places"
                style={{ marginTop: allSectionsSelected() ? "0px" : "-15px" }}
              >
                {isContentSelected("Section 1", "Content 5") &&
                  placesToggle && (
                    <div style={{ pageBreakAfter: "always" }}>
                      <h5 className="text-left mb-6 font-bold">Places:</h5>
                      <div style={{ marginTop: "-10px" }}>
                        <PlacesList places={places} />
                      </div>
                      <br />
                    </div>
                  )}
              </div>
            </div>

            <div
              className="relative bg-red-500 h-full"
              id="export-modal-settings"
            >
              <div
                style={{
                  height: window.innerHeight - 160,
                }}
                className="fixed flex flex-col justify-between"
              >
                <div className="h-full ml-24 ">
                  <h5>Settings</h5>
                  <hr className="w-[250px]" />
                  <Form.Check
                    type="checkbox"
                    label="Map"
                    checked={isContentSelected("Section 1", "Content 1")}
                    onChange={() => toggleContent("Section 1", "Content 1")}
                    disabled={isochrone.length === 0}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Population Density"
                    checked={
                      populationToggle
                        ? isContentSelected("Section 1", "Content 2")
                        : false
                    }
                    onChange={() => toggleContent("Section 1", "Content 2")}
                    disabled={!populationToggle}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Socioeconomic Data"
                    checked={isContentSelected("Section 1", "Content 3")}
                    onChange={() => toggleContent("Section 1", "Content 3")}
                    disabled={isochrone.length === 0}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Population Data"
                    checked={isContentSelected("Section 1", "Content 4")}
                    onChange={() => toggleContent("Section 1", "Content 4")}
                    disabled={isochrone.length === 0}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Top Retail"
                    checked={
                      placesToggle
                        ? isContentSelected("Section 1", "Content 5")
                        : false
                    }
                    onChange={() => toggleContent("Section 1", "Content 5")}
                    disabled={!placesToggle}
                  />
                </div>
                <Button
                  id="theme-button"
                  variant="primary"
                  onClick={generatePDF}
                  className="w-[75%] mt-[80%] ml-24 "
                >
                  Export
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const MapDisplay = ({
  center,
  isochrone,
  refPoly,
  populationData,
  populationToggle,
}: any) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [heatmap] = useState(
    new google.maps.visualization.HeatmapLayer({
      data: populationData,
    })
  );

  useEffect(() => {
    if (populationData.length) {
      if (populationToggle) {
        heatmap.setMap(mapRef.current);
      } else {
        heatmap.setMap(null);
      }
    }
  }, [populationToggle, populationData.length]);

  return (
    <div className="h-[400px] w-full">
      <GoogleMap
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl: false,
        }}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          borderRadius: 12,
        }}
        zoom={15}
        center={center}
        onLoad={(map) => {
          if (!mapRef.current) {
            mapRef.current = map;
          }
        }}
      >
        <Polygon
          paths={isochrone.map(
            (cords: any) =>
              new window.google.maps.LatLng({
                lat: cords[1],
                lng: cords[0],
              })
          )}
          ref={refPoly}
          editable={false}
          draggable={false}
          options={{
            fillColor: "#ff1d7f",
            fillOpacity: 0.15,
            strokeWeight: 3,
            strokeColor: "#ff1d7f",
          }}
        />
        {center && <Marker position={center} zIndex={51} />}
      </GoogleMap>
    </div>
  );
};

const DemographicsDisplay: React.FC<NSEDemographicsDisplayProps> = ({
  nse,
  demographics,
}) => {
  const color: any = {
    AB: "#198754",
    C_: "#0d6efd",
    C: "#0dcaf0",
    C__10: "#ffc107",
    D_: "#dc3545",
    D: "#adb5bd",
    E: "black",
  };

  return (
    <div>
      {nse.total ? (
        <>
          <div className="flex justify-between">
            <div className="bg-white rounded-xl flex flex-col gap-2">
              {Object.keys(nse).map(
                (key, i) =>
                  key !== "total" && (
                    <div key={i} className="flex items-center gap-3 text-black">
                      <div
                        className="w-12 h-5 text-[12px] flex items-center justify-center"
                        style={{
                          backgroundColor: color[key as any],
                          color: "white",
                          width: `${
                            sociowidth + (nse[key] / nse.total) * 300
                          }px`,
                          borderRadius: "10px",
                          paddingBottom: "14px",
                        }}
                      >
                        {key.replace("__10", "-").replace("_", "+")}
                      </div>
                      <div className="h-6 flex items-center justify-center rounded">
                        {`${((nse[key] / nse.total) * 100).toFixed(2)} %`}
                      </div>
                      <div className="h-6 flex items-center justify-center rounded">
                        <span className="mr-1">{nse[key]}</span>
                        <span>Houses</span>
                      </div>
                    </div>
                  )
              )}
            </div>

            <div className="flex flex-col gap-2 bg-white p-2 rounded-xl min-w-[370px]">
              {[
                { label: "AUTOM", value: demographics.AUTOM },
                { label: "MOTO", value: demographics.MOTO },
                { label: "BICI", value: demographics.BICI },
                { label: "PC", value: demographics.PC },
                { label: "STVP", value: demographics.STVP },
                { label: "SPMVPI", value: demographics.SPMVPI },
                { label: "CVJ", value: demographics.CVJ },
              ].map((item, idx) => (
                <div className="flex gap-1 items-center" key={idx}>
                  <span className="font-bold">{item.label}: </span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

const PopulationDisplay = ({ demographics }: any) => {
  return (
    <div className="flex justify-between">
      {demographics?.males && (
        <div className="bg-white rounded-xl ">
          <div className="flex items-center gap-2 mb-2">
            <div className="pt-3.5">
              <BsGenderMale />
            </div>
            <h6 className="font-bold m-0">Males</h6>
          </div>

          {Object.keys(demographics.males).map((key, i) =>
            key !== "total" ? (
              <div key={i} className="flex items-center gap-1 mb-2">
                <div
                  className="w-24"
                  style={{
                    color: `rgb(${0 + i * 15}, ${0 + i * 15}, ${0 + i * 15})`,
                  }}
                >
                  {key.split("_").join(" ")}
                </div>
                <div
                  className="h-5 text-[12px] flex items-center justify-center "
                  style={{
                    backgroundColor: `rgb(${0 + i * 15}, ${0 + i * 15}, ${
                      0 + i * 15
                    })`,
                    color: "white",
                    width: `${
                      baseWidth +
                      (demographics.males[key] / demographics.males.total) * 400
                    }px`,
                    borderRadius: "10px",
                    paddingBottom: "14px",
                  }}
                >
                  {`${(
                    (demographics.males[key] / demographics.males.total) *
                    100
                  ).toFixed(2)}%`}
                </div>
              </div>
            ) : null
          )}
        </div>
      )}

      {demographics?.females && (
        <div className="bg-white p-2 rounded-xl">
          <div className="flex items-center gap-2 mb-2">
            <div className="pt-3.5">
              <BsGenderFemale />
            </div>
            <h6 className="font-bold m-0">Females</h6>
          </div>

          {Object.keys(demographics.females).map((key, i) =>
            key !== "total" ? (
              <div key={i} className="flex items-center gap-2 mb-2 text-black">
                <div
                  className="w-24"
                  style={{
                    color: `rgb(${0 + i * 15}, ${0 + i * 15}, ${0 + i * 15})`,
                  }}
                >
                  {key.split("_").join(" ")}
                </div>
                <div
                  className="h-5 text-[12px] flex items-center justify-center "
                  style={{
                    backgroundColor: `rgb(${0 + i * 15}, ${0 + i * 15}, ${
                      0 + i * 15
                    })`,
                    color: "white",
                    width: `${
                      baseWidth +
                      (demographics.females[key] / demographics.females.total) *
                        400
                    }px`,
                    borderRadius: "10px",
                    paddingBottom: "14px",
                  }}
                >
                  {`${(
                    (demographics.females[key] / demographics.females.total) *
                    100
                  ).toFixed(2)}%`}
                </div>
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

const PlacesList: React.FC<PlacesListProps> = ({ places }) => {
  const groupedPlaces: { [key: string]: { name: string; length: number }[] } =
    {};
  places.forEach((place) => {
    if (!groupedPlaces[place.type]) {
      groupedPlaces[place.type] = [];
    }
    groupedPlaces[place.type].push({ name: place.name, length: place.length });
  });

  return (
    <div className="w-[550px] p-2 bg-white rounded-xl -mt-[14px]">
      {Object.keys(groupedPlaces).map((type, index) => (
        <div key={index} className="mt-2">
          <span className="capitalize font-bold">{type.replace("_", " ")}</span>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {groupedPlaces[type].map((place, idx) => (
              <div key={idx} className="text-sm">
                {place.name} ({place.length})
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Pdf;
