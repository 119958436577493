import blockbusterPNG from "@Assets/images/blockbuster.png";

export default function Home() {
  return (
    <>
      {/* Mobile Layout */}
      <div className="mobile-layout md:hidden">
        <div className="flex flex-col justify-center items-center bg-csecondary h-screen">
          <div className="w-4/5 md:w-3/4 text-center">
            <h1 className="text-xl text-left md:text-3xl font-bold">
              Bienvenidos a{" "}
              <span className="text-cprimary">Locales Fantásticos</span>
            </h1>
            <p className="text-sm text-left md:text-base">
              Encontrar la ubicación ideal para tu marca es es un paso crucial
              hacia el éxito. En "Locales Fantásticos", combinamos la innovación
              tecnológica con análisis de datos avanzados para ofrecerte una
              herramienta única en la exploración de geodata, diseñada para
              llevarte justo al lugar que tu marca necesita.
            </p>
          </div>
          <img src={blockbusterPNG} className="w-full mt-4" />
        </div>
      </div>

      {/* Laptop Layout */}
      <div className="laptop-layout hidden md:block">
        <div className="flex h-screen mt-[-70px]">
          <div className="absolute top-0 z-[-1] left-0 h-full w-[50vw] bg-cprimary"></div>
          <div className="absolute top-0 z-[-1] left-0 h-full w-[49vw] bg-[#FE50E3]"></div>
          <div className="absolute top-0 z-[-1] left-0 h-full w-[48vw] bg-csecondary"></div>
          <div className="w-[50vw] flex justify-center items-center">
            <div className="flex flex-col gap-4 w-[35vw]">
              <h1 className="text-xl md:text-3xl font-bold">
                Bienvenidos a{" "}
                <span className="text-cprimary">Locales Fantásticos</span>
              </h1>
              <p className="text-sm md:text-base w-4/5">
                Encontrar la ubicación ideal para tu marca es es un paso crucial
                hacia el éxito. En "Locales Fantásticos", combinamos la
                innovación tecnológica con análisis de datos avanzados para
                ofrecerte una herramienta única en la exploración de geodata,
                diseñada para llevarte justo al lugar que tu marca necesita.
              </p>
            </div>
          </div>
          <div className="w-[50vw]">
            <img src={blockbusterPNG} className="w-[50vw] h-full" />
          </div>
        </div>
      </div>
    </>
  );
}
