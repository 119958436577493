import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useData } from "@Contexts/DataContext";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "@Config/firebase";
import { useAuth } from "@Contexts/AuthContext";
import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import { Alert } from "react-bootstrap"; // Import Bootstrap Alert
import { FaParking, FaMapMarkerAlt } from "react-icons/fa";
import { GoChevronUp, GoChevronDown } from "react-icons/go";
import { AiFillShop, AiOutlineClose } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { BsBuildings } from "react-icons/bs";
import { RxCorners, RxCornerTopRight } from "react-icons/rx";
import { Form, Row, Col, FloatingLabel } from "react-bootstrap";
import { MdWarning } from "react-icons/md";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const EditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isTaskClicked, setIsTaskClicked] = useState(false);
  const [center, setCenter] = useState({ lat: 18.8975617, lng: -96.9385916 });
  const { projects, organizations, locales } = useData();
  const [pipeline, setPipeline] = useState<any>(null);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { user } = useAuth();
  const mapRef = useRef<google.maps.Map | null>(null);
  const [filteredLocales, setFilteredLocales] = useState<any[]>([]);
  const [polygonDrawn, setPolygonDrawn] = useState(false);
  const [showNoLocalesMessage, setShowNoLocalesMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [opacity, setOpacity] = useState(0);
  const [selectedTask, setSelectedTask] = useState<Taskupdate | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    let timer: number | undefined;
    if (isTaskClicked) {
      timer = window.setTimeout(() => {
        setOpacity(1);
      }, 350);
    }
    return () => {
      if (timer !== undefined) {
        window.clearTimeout(timer);
      }
    };
  }, [isTaskClicked]);

  const divStyle = {
    width: "39%",
    opacity: opacity,
    transition: "opacity 0.5s ease",
    height: "90vh",
  };

  useEffect(() => {
    if (
      selectedTask &&
      typeof selectedTask.stageIndex === "number" &&
      typeof selectedTask.taskIndex === "number"
    ) {
      const updatedPipeline = JSON.parse(JSON.stringify(pipeline));
      const { stageIndex, taskIndex } = selectedTask;
      const stages = Object.keys(updatedPipeline);
      const stage = stages[stageIndex];
      const tasks = updatedPipeline[stage] as HierarchicalPipeline;

      if (tasks) {
        const taskKeys = Object.keys(tasks);
        const taskKey = taskKeys[taskIndex];
        const task = tasks[taskKey];
        if (typeof task === "object" && task !== null && !Array.isArray(task)) {
          const { startDate, endDate, description } = task;
          setStartDate(typeof startDate === "string" ? startDate : "");
          setEndDate(typeof endDate === "string" ? endDate : "");
          setDescription(typeof description === "string" ? description : "");
        } else {
          setStartDate("");
          setEndDate("");
          setDescription("");
        }
      }
    }
  }, [selectedTask, pipeline]);

  useEffect(() => {
    const project = projects.find((project) => project.id === id);
    if (project) {
      setSelectedProject(project);
      setPipeline(project.pipeline || {});
      setModalOpen(true);

      if (project.isochroneCoordinates?.length) {
        const firstCoordinate = project.isochroneCoordinates[0];
        setCenter({
          lat: firstCoordinate.latitude,
          lng: firstCoordinate.longitude,
        });
      }
    }
  }, [id, projects]);

  useEffect(() => {
    if (selectedProject?.isochroneCoordinates) {
      setPolygonDrawn(true);
    } else {
      setPolygonDrawn(false);
    }
  }, [selectedProject?.isochroneCoordinates]);

  useEffect(() => {
    if (
      polygonDrawn &&
      selectedProject?.isochroneCoordinates &&
      mapRef.current
    ) {
      const isochroneCoordinates = selectedProject.isochroneCoordinates.map(
        (coord: { latitude: number; longitude: number }) => ({
          lat: coord.latitude,
          lng: coord.longitude,
        })
      );

      const updatedFilteredLocales = locales.filter(
        (locale: any) =>
          selectedProject?.localeIds?.includes(locale.id) &&
          isLocaleInPolygon(
            locale.location.latitude,
            locale.location.longitude,
            isochroneCoordinates
          )
      );
      setFilteredLocales(updatedFilteredLocales);
      setShowNoLocalesMessage(updatedFilteredLocales.length === 0);
    }
  }, [polygonDrawn, selectedProject, locales, mapRef]);

  const userDefaultOrgId = user?.data?.default_organization;
  const organization = organizations?.find(
    (org) => org.id === userDefaultOrgId
  );

  const onPipelineChange = (updatedPipeline: any) => {
    setPipeline(updatedPipeline);
  };

  const onSave = async (updatedPipeline: HierarchicalPipeline) => {
    if (selectedProject && organization) {
      try {
        const projectRef = doc(
          db,
          `organizations/${organization.id}/projects`,
          selectedProject.id
        );
        const projectDoc = await getDoc(projectRef);
        if (projectDoc.exists()) {
          const existingData = projectDoc.data() || {};
          const updatedData = {
            ...existingData,
            pipeline: deepMerge(existingData.pipeline || {}, updatedPipeline),
          };
          await updateDoc(projectRef, updatedData);
          setPipeline(updatedPipeline);
          setAlertMessage("Changes saved successfully!");
          setTimeout(() => setAlertMessage(null), 3000);
        } else {
          console.error("No such document!");
          setAlertMessage("Project document does not exist.");
          setTimeout(() => setAlertMessage(null), 3000);
        }
      } catch (e) {
        console.error("Error updating document: ", e);
        setAlertMessage("Error updating document. Please try again.");
        setTimeout(() => setAlertMessage(null), 3000);
      }
    }
  };

  const onTaskSave = async (updatedTask: any) => {
    if (selectedProject && organization) {
      try {
        const taskIndex = selectedProject.tasks.findIndex(
          (task: any) => task.id === updatedTask.id
        );

        if (taskIndex !== -1) {
          const updatedProject = {
            ...selectedProject,
            tasks: [
              ...selectedProject.tasks.slice(0, taskIndex),
              updatedTask,
              ...selectedProject.tasks.slice(taskIndex + 1),
            ],
          };

          const projectRef = doc(
            db,
            `organizations/${organization.id}/projects`,
            selectedProject.id
          );
          await updateDoc(projectRef, {
            tasks: updatedProject.tasks,
          });

          setPipeline(updatedProject.pipeline || {});
          setAlertMessage("Changes saved successfully!");
          setTimeout(() => setAlertMessage(null), 3000);
        }
      } catch (e) {
        console.error("Error updating document: ", e);
      }
    }
  };

  const handleRemoveLocale = async (localeId: string) => {
    if (selectedProject && organization) {
      try {
        const updatedLocaleIds = selectedProject.localeIds.filter(
          (id: string) => id !== localeId
        );
        const projectRef = doc(
          db,
          `organizations/${organization.id}/projects`,
          selectedProject.id
        );
        await updateDoc(projectRef, {
          localeIds: updatedLocaleIds,
        });

        setFilteredLocales(
          filteredLocales.filter((locale) => locale.id !== localeId)
        );
      } catch (e) {
        console.error("Error removing locale: ", e);
      }
    }
  };

  if (!selectedProject) {
    return <div>Project not found</div>;
  }

  const isochroneCoordinates = selectedProject.isochroneCoordinates?.map(
    (coord: { latitude: number; longitude: number }) => ({
      lat: coord.latitude,
      lng: coord.longitude,
    })
  );

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();

    if (typeof onTaskSave === "function" && selectedTask) {
      const updatedPipeline = JSON.parse(JSON.stringify(pipeline));
      const { stageIndex, taskIndex } = selectedTask;
      if (typeof stageIndex === "number" && typeof taskIndex === "number") {
        const stages = Object.keys(updatedPipeline);
        const stage = stages[stageIndex];
        const tasks = updatedPipeline[stage] as HierarchicalPipeline;
        if (tasks) {
          const taskKeys = Object.keys(tasks);
          const taskKey = taskKeys[taskIndex];
          const task = tasks[taskKey];
          if (typeof task === "object" && !Array.isArray(task)) {
            updatedPipeline[stage] = {
              ...tasks,
              [taskKey]: {
                ...task,
                startDate,
                endDate,
                description,
              },
            };
          } else if (typeof task === "boolean") {
            updatedPipeline[stage] = {
              ...tasks,
              [taskKey]: {
                startDate,
                endDate,
                description,
              },
            };
          }
          onSave(updatedPipeline);
        } else {
          console.error("Tasks not found in the selected stage");
        }
      } else {
        console.error("Invalid stageIndex or taskIndex");
      }
    }
  };

  const isLocaleInPolygon = (
    lat: number,
    lng: number,
    coordinates: google.maps.LatLngLiteral[]
  ) => {
    if (!mapRef.current || !coordinates || !google.maps.geometry) {
      console.error("Map, coordinates, or geometry library is missing");
      return false;
    }
    const point = new google.maps.LatLng(lat, lng);
    const polygon = new google.maps.Polygon({ paths: coordinates });
    try {
      return google.maps.geometry.poly.containsLocation(point, polygon);
    } catch (e) {
      console.error("Error checking if point is in polygon:", e);
      return false;
    }
  };

  return (
    <>
      <div style={{ paddingLeft: 250 }} className="mt-[16px] ">
        <h2 className="font-bold text-cprimary mx-4">{selectedProject.name}</h2>
        <hr
          className="mt-[16px] mx-4"
          style={{ border: "1px rgb(255 29 127) solid" }}
        />
        <div className="flex justify-between">
          <div className="flex-1">
            <div
              className="flex "
              style={{
                transform: isTaskClicked ? "translateX(-50%)" : "100%",
                marginLeft: isTaskClicked ? "0px" : "10px",
                marginRight: isTaskClicked ? "0px" : "10px",
                transition: "transform 0.7s ease",
              }}
            >
              <div className="flex-1 ">
                <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                  <GoogleMap
                    mapContainerStyle={{
                      height: "390px",
                      width: "100%",
                    }}
                    center={center}
                    zoom={16}
                    options={{
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      zoomControl: false,
                    }}
                    onLoad={(map) => {
                      mapRef.current = map;
                    }}
                  >
                    {polygonDrawn && isochroneCoordinates && (
                      <Polygon
                        paths={isochroneCoordinates}
                        options={{
                          fillColor: "#ff1d7f",
                          fillOpacity: 0.15,
                          strokeWeight: 3,
                          strokeColor: "#ff1d7f",
                        }}
                      />
                    )}

                    {polygonDrawn &&
                      filteredLocales.map((locale: any) => (
                        <Marker
                          key={locale.id}
                          position={{
                            lat: locale.location.latitude,
                            lng: locale.location.longitude,
                          }}
                          icon={{
                            url: "/shop.svg",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                        />
                      ))}
                  </GoogleMap>
                </div>
                <div className="mt-3">
                  {" "}
                  {filteredLocales.length > 0 ? (
                    <div className="flex flex-wrap gap-2 ">
                      {filteredLocales.map((locale: any, idx: any) => (
                        <div
                          key={idx}
                          className=" flex-1 min-w-[310px] max-w-[calc(50%)] flex flex-col relative "
                        >
                          <div className="relative rounded-lg border p-2 bg-white shadow-sm flex-grow flex flex-col">
                            <img
                              src={locale.images[0]}
                              alt="Locale"
                              height={80}
                              className="w-full h-auto rounded min-w-[100%] drop-shadow-sm"
                            />
                            <div className="p-2 flex flex-col flex-grow">
                              <h3
                                className="text-sm mb-1 font-bold"
                                style={{ minHeight: "2.5em" }}
                              >
                                {locale.location.address}
                              </h3>

                              <div className="flex gap-1 flex-wrap opacity-70">
                                <h3 className="flex gap-1 items-center text-xs">
                                  <RxCorners className="text-[10px]" />
                                  <span className="text-[11px]">
                                    {locale.sqMeter} m<sup>2</sup>
                                  </span>
                                </h3>
                                <h3 className="flex gap-1 items-center text-xs">
                                  <BsBuildings className="text-[10px]" />
                                  <span className="text-[11px]">
                                    {locale.floors} Floor(s)
                                  </span>
                                </h3>

                                {locale.haveParking && (
                                  <h3 className="flex gap-1 items-center text-xs">
                                    <FaParking className="text-[10px]" />
                                    <span className="text-[11px]">Parking</span>
                                  </h3>
                                )}

                                {locale.isCorner && (
                                  <h3 className="flex gap-1 items-center text-xs">
                                    <RxCornerTopRight className="text-[10px]" />
                                    <span className="text-[11px]">Corner</span>
                                  </h3>
                                )}

                                {locale.commercial && (
                                  <h3 className="flex gap-1 items-center text-xs">
                                    <AiFillShop className="text-[10px]" />
                                    <span className="text-[11px]">
                                      Commercial
                                    </span>
                                  </h3>
                                )}
                              </div>
                              <div className="flex justify-between items-end mt-2">
                                <h1 className="text-[16px] font-bold">
                                  {USDollar.format(locale.price)}
                                </h1>

                                <Button
                                  onClick={() => handleRemoveLocale(locale.id)}
                                  id="theme-button"
                                  className="position-absolute bottom-4 end-14 d-flex align-items-center justify-content-center shadow"
                                  variant="outline-danger"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                >
                                  <AiOutlineClose />
                                </Button>
                                <Button
                                  className="flex items-center justify-center bg-yellow-400 text-white w-[40px] h-[40px] rounded-xl cursor-pointer mr-[-5px] p-0"
                                  id="theme-button"
                                  onClick={() =>
                                    setCenter({
                                      lat: locale.location.latitude,
                                      lng: locale.location.longitude,
                                    })
                                  }
                                >
                                  <FaMapMarkerAlt className="ml-[11px]" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    showNoLocalesMessage && (
                      <div className="p-4 text-center text-lg text-gray-600">
                        No Locale Available in this Isochrone
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className={`w-1/2 h-[calc(100%-50px)] overflow-y-auto`}>
                <div>
                  {modalOpen && selectedProject && (
                    <PipelineModal
                      onSave={onSave}
                      pipeline={pipeline}
                      onPipelineChange={onPipelineChange}
                      setIsTaskClicked={setIsTaskClicked}
                      setSelectedTask={setSelectedTask}
                      alertMessage={alertMessage}
                      setAlertMessage={setAlertMessage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {isTaskClicked && (
            <div
              className="absolute left-[59vw] right-[-10%] w-1/2 min-h-[370px] bg-white p-2 flex flex-col"
              style={{
                ...divStyle,
              }}
            >
              <form className="mt-[-20px] d-flex flex-column h-100">
                <input
                  type="text"
                  className="mb-3 mt-3"
                  placeholder="Task Name"
                  value={selectedTask?.name || "Please Save the Task"}
                  style={{
                    fontSize: selectedTask?.name ? "24px" : "20px",
                    fontWeight: "bold",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    color: selectedTask?.name ? "inherit" : "red",
                  }}
                  readOnly
                  // onChange={(e) => {
                  //   setSelectedTask(e.target.value);
                  // }}
                />

                <Row className="mb-3">
                  <Col>
                    <FloatingLabel controlId="startDate" label="Start Date">
                      <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel controlId="endDate" label="End Date">
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <FloatingLabel
                  controlId="membersDropdown"
                  label="Members"
                  className="mb-3"
                >
                  <Form.Select aria-label="Select member" defaultValue="">
                    <option value="" disabled hidden></option>
                    <option value="1">Member 1</option>
                    <option value="2">Member 2</option>
                    <option value="3">Member 3</option>
                  </Form.Select>
                </FloatingLabel>

                <FloatingLabel
                  controlId="description"
                  label="Description"
                  className="mb-3 flex-grow-1"
                  style={{ flexGrow: 1 }}
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100%", resize: "none" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FloatingLabel>

                <div className="d-flex justify-content-end">
                  <Button
                    id="theme-button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleSave}
                    disabled={!selectedTask?.name}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditPage;

const mapContainerWidth = "100%";

const deepMerge = (target: any, source: any): any => {
  if (typeof source !== "object" || source === null) {
    return source;
  }

  if (Array.isArray(source)) {
    return [...source];
  }

  const output = { ...target };
  for (const key of Object.keys(source)) {
    if (
      source[key] &&
      typeof source[key] === "object" &&
      !Array.isArray(source[key])
    ) {
      output[key] = deepMerge(target[key], source[key]);
    } else {
      output[key] = source[key];
    }
  }
  return output;
};

const convertToPipelineStages = (
  pipeline: HierarchicalPipeline
): PipelineStage[] => {
  return Object.entries(pipeline).map(([stageName, tasks]) => {
    const stageTasks: PipelineTask[] = Object.entries(tasks).map(
      ([taskName, subtasksOrSelected]) => {
        if (typeof subtasksOrSelected === "boolean") {
          return {
            name: taskName || "",
            selected: subtasksOrSelected,
            subtasks: [],
          };
        } else {
          const taskSelected = Object.values(subtasksOrSelected).every(
            (value) => value === true
          );
          const subtasks: PipelineSubtask[] = Object.entries(
            subtasksOrSelected
          ).map(([subtaskName, selected]) => ({
            name: subtaskName || "",
            selected: selected as boolean,
          }));
          return {
            name: taskName || "",
            selected: taskSelected,
            subtasks,
          };
        }
      }
    );

    let stageSelected: boolean;
    if (stageTasks.length === 0) {
      stageSelected = pipeline[stageName] as boolean;
    } else {
      stageSelected = stageTasks.every((task) => task);
    }

    return {
      name: stageName || "",
      selected: stageSelected,
      tasks: stageTasks,
    };
  });
};

const transformToHierarchical = (
  stages: PipelineStage[]
): HierarchicalPipeline => {
  return stages.reduce((acc, stage) => {
    if (stage.tasks.length === 0) {
      // If no tasks, assign boolean directly
      acc[stage.name] = stage.selected as boolean;
    } else {
      const taskAcc: Record<string, boolean | Record<string, boolean>> = {};
      stage.tasks.forEach((task) => {
        if (task.subtasks.length === 0) {
          // If no subtasks, assign boolean directly
          taskAcc[task.name] = task.selected as boolean;
        } else {
          const subtaskAcc: Record<string, boolean> = {};
          task.subtasks.forEach((subtask) => {
            // Only include subtasks that have a boolean value
            if (subtask.selected !== undefined) {
              subtaskAcc[subtask.name] = subtask.selected as boolean;
            }
          });
          // Assign task with subtasks if there are any subtasks
          taskAcc[task.name] =
            Object.keys(subtaskAcc).length > 0
              ? subtaskAcc
              : (task.selected as boolean);
        }
      });
      acc[stage.name] = taskAcc;
    }
    return acc;
  }, {} as HierarchicalPipeline);
};

const getExpiredTasks = (pipeline: HierarchicalPipeline): Set<string> => {
  const today = new Date();
  const expiredTasks = new Set<string>();

  const extractEndDates = (obj: any) => {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === "boolean") return; // Skip boolean values

        if (typeof value === "object" && value !== null) {
          // Use the key as the task name
          const taskName = key;

          // Check if the object has an endDate field
          const { endDate } = value as { endDate?: string };

          if (endDate) {
            const endDateObj = new Date(endDate);
            if (endDateObj < today) {
              expiredTasks.add(taskName); // Use task name as identifier
            }
          }

          // Recursively process nested objects
          extractEndDates(value);
        }
      });
    }
  };

  extractEndDates(pipeline);
  return expiredTasks;
};

const PipelineModal: React.FC<PipelineModalProps> = ({
  onSave,
  pipeline,
  onPipelineChange,
  setIsTaskClicked,
  setSelectedTask,
  alertMessage,
  setAlertMessage,
}) => {
  const [stages, setStages] = useState<PipelineStage[]>(
    convertToPipelineStages(pipeline)
  );
  const [isSaving, setIsSaving] = useState(false);
  const [expandedStages, setExpandedStages] = useState<number[]>([]);
  const [expandedTasks, setExpandedTasks] = useState<{
    [key: number]: number[];
  }>({});

  const toggleStageExpand = (index: number) => {
    setExpandedStages((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const toggleTaskExpand = (stageIndex: number, taskIndex: number) => {
    setExpandedTasks((prev) => ({
      ...prev,
      [stageIndex]: prev[stageIndex]?.includes(taskIndex)
        ? prev[stageIndex].filter((i) => i !== taskIndex)
        : [...(prev[stageIndex] || []), taskIndex],
    }));
  };

  const handleStageNameChange = (index: number, name: string) => {
    const newStages = [...stages];
    newStages[index].name = name.trim() || "";
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const handleTaskNameChange = (
    stageIndex: number,
    taskIndex: number,
    name: string
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].name = name.trim() || "";
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const handleSubtaskNameChange = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number,
    name: string
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex].name =
      name.trim() || "";
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const addStage = () => {
    const newStage = { name: "", selected: false, tasks: [] };
    const newStages = [...stages, newStage];
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const deleteStage = (index: number) => {
    const newStages = stages.filter((_, i) => i !== index);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const addTask = (stageIndex: number) => {
    const newTask: PipelineTask = {
      name: "",
      selected: false,
      subtasks: [],
    };

    const newStages = [...stages];
    newStages[stageIndex].tasks.push(newTask);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const deleteTask = (stageIndex: number, taskIndex: number) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks = newStages[stageIndex].tasks.filter(
      (_, i) => i !== taskIndex
    );
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const addSubtask = (stageIndex: number, taskIndex: number) => {
    const newSubtask = { name: "", selected: false };
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks.push(newSubtask);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const deleteSubtask = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks = newStages[
      stageIndex
    ].tasks[taskIndex].subtasks.filter((_, i) => i !== subtaskIndex);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const handleStageSelectionChange = (stageIndex: number) => {
    const newStages = [...stages];
    const isSelected = !newStages[stageIndex].selected;

    newStages[stageIndex].selected = isSelected;

    newStages[stageIndex].tasks = newStages[stageIndex].tasks.map((task) => {
      return {
        ...task,
        selected: isSelected,
        subtasks: task.subtasks.map((subtask) => {
          return {
            ...subtask,
            selected:
              typeof subtask.selected === "boolean"
                ? isSelected
                : subtask.selected,
          };
        }),
      };
    });

    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const handleTaskSelectionChange = (stageIndex: number, taskIndex: number) => {
    const newStages = [...stages];
    const task = newStages[stageIndex].tasks[taskIndex];
    const newTask = { ...task, selected: !task.selected };
    const newSubtasks = task.subtasks.map((subtask) => ({
      ...subtask,
      selected:
        typeof subtask.selected === "boolean"
          ? newTask.selected
          : subtask.selected,
    }));
    newStages[stageIndex].tasks[taskIndex] = {
      ...newTask,
      subtasks: newSubtasks,
    };

    // Check if all tasks are selected
    const allTasksSelected = newStages[stageIndex].tasks.every(
      (task) => task.selected
    );
    newStages[stageIndex].selected = allTasksSelected;

    setStages(newStages); // Update state
    onPipelineChange({ stages: newStages }); // Notify change
    setIsTaskClicked(true); // Update clicked state
  };

  const saveJson = async () => {
    setIsSaving(true);
    const pipelineObject = transformToHierarchical(stages);
    try {
      await onSave(pipelineObject);
    } catch (error) {
      console.error("Failed to save pipeline:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSubtaskSelectionChange = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number
  ) => {
    const newStages = [...stages];
    const task = newStages[stageIndex].tasks[taskIndex];
    const subtask = task.subtasks[subtaskIndex];
    subtask.selected = !subtask.selected;

    const allSubtasksSelected = task.subtasks.every(
      (subtask) => subtask.selected
    );
    task.selected = allSubtasksSelected;

    const allTasksSelected = newStages[stageIndex].tasks.every(
      (task) => task.selected
    );
    newStages[stageIndex].selected = allTasksSelected;
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  return (
    <>
      <div className="space-y-4" style={{ width: mapContainerWidth }}>
        {alertMessage && (
          <Alert
            variant="danger"
            onClose={() => setAlertMessage(null)}
            dismissible
            className=" fixed top-0 left-1/2 transform -translate-x-1/2  w-2/3 text-black text-center p-2 border-b border-gray-200 shadow-lg"
          >
            {alertMessage}
          </Alert>
        )}
        {stages.map((stage, stageIndex) => (
          <div key={stageIndex} className="group mb-[16px]">
            <div className="flex items-center justify-between pl-4 mb-3 ">
              <Button
                onClick={() => toggleStageExpand(stageIndex)}
                className="d-flex align-items-center p-0 border-0 bg-transparent text-dark mr-5"
                variant="link"
              >
                {expandedStages.includes(stageIndex) ? (
                  <GoChevronUp size={20} />
                ) : (
                  <GoChevronDown size={20} />
                )}
              </Button>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={stage.selected}
                  onChange={() => handleStageSelectionChange(stageIndex)}
                  className="sr-only"
                />
                <span
                  className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ${
                    stage.selected
                      ? "bg-green-500 border-green-500"
                      : "bg-transparent border-gray-300"
                  }`}
                >
                  <FaCheck
                    className={`text-${stage.selected ? "white" : "gray-400"}`}
                    size={10}
                  />
                </span>
              </label>

              <input
                type="text"
                value={stage.name}
                onChange={(e) =>
                  handleStageNameChange(stageIndex, e.target.value)
                }
                placeholder={`Stage ${stageIndex + 1}`}
                className="ml-6 p-1 border-none outline-none w-full"
              />

              {/* Show delete icon on hover */}
              <div className="group-hover:flex hidden">
                <Button
                  onClick={() => deleteStage(stageIndex)}
                  className="p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                  variant="link"
                >
                  <AiOutlineDelete
                    size={18}
                    className="text-red-500 hover:bg-red-200"
                  />
                </Button>
              </div>

              <Button
                onClick={() => addTask(stageIndex)}
                className="p-1 hover:bg-green-200 rounded-full text-green-500 hover:text-green-600"
                variant="link"
              >
                <AiOutlinePlus
                  size={16}
                  className="text-green-500 hover:bg-green-200"
                />
              </Button>
            </div>
            {expandedStages.includes(stageIndex) && (
              <div className="pl-4 ml-2 mt-3">
                {stage.tasks.map((task, taskIndex) => (
                  <div
                    key={taskIndex}
                    className="flex flex-col mb-[16px] group"
                  >
                    <div className="flex items-center ">
                      <div>
                        {getExpiredTasks(pipeline).has(task.name) && (
                          <MdWarning
                            size={18}
                            style={{
                              color: "red",
                              marginRight: "-18px",
                              marginLeft: "-10px",
                              marginBottom: "2px",
                            }}
                          />
                        )}
                      </div>

                      <Button
                        onClick={() => toggleTaskExpand(stageIndex, taskIndex)}
                        className="d-flex align-items-center p-0 border-0 bg-transparent text-dark mr-5"
                        variant="link"
                      >
                        {expandedTasks[stageIndex]?.includes(taskIndex) ? (
                          <GoChevronUp size={20} className="ml-3" />
                        ) : (
                          <GoChevronDown size={20} className="ml-3" />
                        )}
                      </Button>

                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={task.selected}
                          onChange={() =>
                            handleTaskSelectionChange(stageIndex, taskIndex)
                          }
                          className="sr-only"
                        />
                        <span
                          className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ${
                            task.selected
                              ? "bg-green-500 border-green-500"
                              : "bg-transparent border-gray-300"
                          }`}
                        >
                          <FaCheck
                            className={`text-${
                              task.selected ? "white" : "gray-400"
                            }`}
                            size={10}
                          />
                        </span>
                      </label>

                      <div
                        className="w-[100%]"
                        onClick={() => {
                          setSelectedTask({
                            name: task.name,
                            stageIndex: stageIndex,
                            taskIndex: taskIndex,
                          });
                          setIsTaskClicked(true);
                        }}
                      >
                        <input
                          type="text"
                          value={task.name}
                          onChange={(e) => {
                            handleTaskNameChange(
                              stageIndex,
                              taskIndex,
                              e.target.value
                            );
                          }}
                          placeholder={`Task ${taskIndex + 1}`}
                          className="p-1 border-none outline-none w-full ml-5"
                        />
                      </div>

                      <div className="group-hover:flex hidden">
                        <Button
                          onClick={() => deleteTask(stageIndex, taskIndex)}
                          className="ml-3 p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                          variant="link"
                        >
                          <AiOutlineDelete
                            size={18}
                            className="text-red-500 hover:bg-red-200"
                          />
                        </Button>
                      </div>
                      <Button
                        onClick={() => addSubtask(stageIndex, taskIndex)}
                        className="p-1 hover:bg-green-200 rounded-full text-green-500 hover:text-green-600"
                        variant="link"
                      >
                        <AiOutlinePlus
                          size={16}
                          className="text-green-500 hover:bg-green-200"
                        />
                      </Button>
                    </div>
                    {expandedTasks[stageIndex]?.includes(taskIndex) && (
                      <div className="pl-8 ml-4">
                        {task.subtasks.map((subtask, subtaskIndex) => {
                          if (
                            ["description", "endDate", "startDate"].includes(
                              subtask.name
                            )
                          ) {
                            return null;
                          }
                          return (
                            <div
                              key={subtaskIndex}
                              className="flex items-center group"
                            >
                              <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={subtask.selected}
                                  onChange={() =>
                                    handleSubtaskSelectionChange(
                                      stageIndex,
                                      taskIndex,
                                      subtaskIndex
                                    )
                                  }
                                  className="sr-only"
                                />
                                <span
                                  className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ml-6 ${
                                    subtask.selected
                                      ? "bg-green-500 border-green-500"
                                      : "bg-transparent border-gray-300"
                                  }`}
                                >
                                  <FaCheck
                                    className={`text-${
                                      subtask.selected ? "white" : "gray-400"
                                    }`}
                                    size={10}
                                  />
                                </span>
                              </label>
                              <input
                                type="text"
                                value={subtask.name}
                                onChange={(e) =>
                                  handleSubtaskNameChange(
                                    stageIndex,
                                    taskIndex,
                                    subtaskIndex,
                                    e.target.value
                                  )
                                }
                                placeholder={`Subtask ${subtaskIndex + 1}`}
                                className="ml-6 p-1 border-none outline-none w-full"
                              />
                              {/* Show delete icon on hover */}
                              <div className="group-hover:flex hidden">
                                <Button
                                  onClick={() =>
                                    deleteSubtask(
                                      stageIndex,
                                      taskIndex,
                                      subtaskIndex
                                    )
                                  }
                                  className="p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                                  variant="link"
                                >
                                  <AiOutlineDelete
                                    size={18}
                                    className="text-red-500 hover:bg-red-200 mr-6"
                                  />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-end space-x-2 pb-3">
          <Button
            className="btn-xs py-1 px-2 rounded text-black bg-transparent border-0"
            id="theme-button"
            variant="primary"
            onClick={saveJson}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
          <Button
            id="theme-button"
            variant="outline-primary"
            onClick={addStage}
          >
            <AiOutlinePlus />
          </Button>
        </div>
      </div>
    </>
  );
};
