/// <reference lib="webworker" />

export default async () => {
  importScripts(
    "https://www.gstatic.com/firebasejs/10.12.2/firebase-app-compat.js"
  );
  importScripts(
    "https://www.gstatic.com/firebasejs/10.12.2/firebase-firestore-compat.js"
  );
  importScripts(
    "https://www.gstatic.com/firebasejs/10.12.2/firebase-auth-compat.js"
  );

  const app = firebase.initializeApp({
    apiKey: "AIzaSyAYxgcpmkfrrjGA6fJsOu89X_wxT2E4Ec0",
    authDomain: "localesfantasticos.firebaseapp.com",
    databaseURL: "https://localesfantasticos-default-rtdb.firebaseio.com",
    projectId: "localesfantasticos",
    storageBucket: "localesfantasticos.appspot.com",
    messagingSenderId: "85476476931",
    appId: "1:85476476931:web:4884b9436563c62068a636",
    measurementId: "G-YYFJV0P2SB",
  });

  const db = firebase.firestore(app);

  self.addEventListener("message", async ({ data: { retails } }) => {
    if (retails?.length) {
      const allLocations = [];

      const locationLoop = retails.map(async (retail) => {
        const locationCollection = await db
          .collection(`retail/${retail.id}/data`)
          .get();

        locationCollection.docs.map((location) =>
          allLocations.push({
            ...location.data(),
            id: location.id,
            pin: retail?.pin || "",
            category: retail?.category || "",
            retailer: retail.name,
          })
        );
        return locationCollection;
      });

      await Promise.all(locationLoop).then(() => {
        self.postMessage(allLocations);
      });
    } else {
      self.postMessage([]);
    }
  });
};
