import { addDoc, collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Modal, Button, FloatingLabel, Form, Table } from "react-bootstrap";
import { db } from "@Config/firebase";
import { useAuth } from "@Contexts/AuthContext";
import { IoMdAdd } from "react-icons/io";

export default function Models() {
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({});
  const [modals, setModals] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getModels = async () => {
    const docs = await getDocs(collection(db, `manager/${user.uid}/models`));
    setModals(docs.docs.map((docu) => ({ ...docu.data(), id: docu.id })));
  };

  const addModal = async () => {
    setLoading(true);
    await addDoc(collection(db, `manager/${user.uid}/models`), values);
    handleClose();
    setLoading(false);
  };

  useEffect(() => {
    getModels();
  }, [show]);

  return (
    <>
      <div
        style={{
          padding: "0px 0px 0px 250px",
        }}
        className="mt-4 mx-4"
      >
        <div className="flex justify-between items-center">
          <h2 className="font-bold text-cprimary">Models</h2>
          <Button id="theme-button" onClick={handleShow}>
            <IoMdAdd />
          </Button>
        </div>
        <hr style={{ border: "1px rgb(255 29 127) solid" }} />
        <div className="main-content">
          {modals.length ? (
            <Table striped bordered>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Medida frente</th>
                  <th scope="col">Medida fondo</th>
                  <th scope="col">Ubicación</th>
                  <th scope="col">Presupuesto</th>
                  <th scope="col">Opciones</th>
                </tr>
              </thead>
              <tbody id="modelos-body">
                {modals.map((modal: any, idx: number) => (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <th>{modal.name}</th>
                    <th>{modal.front}</th>
                    <th>{modal.bottom}</th>
                    <th>{modal.location}</th>
                    <th>${modal.budget}</th>
                    <th>Opciones</th>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center opacity-80">
              You don't have any models.
            </div>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Model</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel label="Model Name" className="mb-2">
            <Form.Control
              type="email"
              className="form-control"
              required
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </FloatingLabel>
          <FloatingLabel label="Front Measurement" className="mb-2">
            <Form.Control
              type="text"
              className="form-control"
              required
              onChange={(e) => setValues({ ...values, front: e.target.value })}
            />
          </FloatingLabel>
          <FloatingLabel label="Bottom Measurement" className="mb-2">
            <Form.Control
              type="text"
              className="form-control"
              required
              onChange={(e) => setValues({ ...values, bottom: e.target.value })}
            />
          </FloatingLabel>
          <FloatingLabel label="Location:" className="mb-2">
            <Form.Control
              type="text"
              className="form-control"
              required
              onChange={(e) =>
                setValues({ ...values, location: e.target.value })
              }
            />
          </FloatingLabel>
          <FloatingLabel label="Budget: ($)" className="mb-2">
            <Form.Control
              type="number"
              className="form-control"
              required
              onChange={(e) => setValues({ ...values, budget: e.target.value })}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button id="theme-button" onClick={addModal} disabled={loading}>
            Save Model
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
