import { useState, useEffect } from "react";
import { LiaRoadSolid } from "react-icons/lia";

const RoadStyleButton = ({
  setToggleMapStyle,
  toggleMapStyle,
  delayedToggleState,
  setDelayedToggleState,
}: any) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();

  const handleMouseEnter = () => {
    if (toggleMapStyle) {
      setTimeoutId(
        setTimeout(() => {
          setDelayedToggleState(true);
        }, 700)
      );
    }
  };

  const handleMouseLeave = () => {
    setDelayedToggleState(false);
  };

  useEffect(() => {
    clearTimeout(timeoutId);
  }, [delayedToggleState]);

  return (
    <div
      id="theme-button"
      style={{
        position: "absolute",
        bottom: "10px",
        left: "10px",
        zIndex: 1000,
        width: delayedToggleState ? "142px" : "45px",
        height: delayedToggleState ? "95px" : "45px",
        borderRadius: delayedToggleState ? "10px" : "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: delayedToggleState ? "rgba(255, 255, 255, 0.75)" : "",
        transition: "width 0.5s, height 0.5s, border-radius 0.5s",
        transform: delayedToggleState ? "scale(1)" : "scale(1)",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        setToggleMapStyle(!toggleMapStyle);
        setDelayedToggleState(true);
      }}
    >
      <div
        className="fade-container"
        style={{
          opacity: delayedToggleState ? 1 : 0,
          transition: "opacity 1s ease-in-out",
          position: "absolute",
          bottom: "10px",
          left: "10px",
          width: delayedToggleState ? "122px" : "0px",
          height: delayedToggleState ? "75px" : "0px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            marginBottom: 10,
            marginTop: 13,
            marginLeft: 1,
            marginRight: 10,
          }}
          className="flex flex-col items-start"
        >
          <div className="flex items-center space-x-2">
            <div
              style={{ marginTop: -28 }}
              className="w-10 h-2 bg-[#AF0380]"
            ></div>
            <p
              style={{ marginTop: -15, fontSize: "small" }}
              className="text-black"
            >
              primary
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div
              style={{ marginTop: -28 }}
              className="w-10 h-2 bg-[#DB3496]"
            ></div>
            <p
              style={{ marginTop: -15, fontSize: "small" }}
              className="text-black"
            >
              secondary
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div
              style={{ marginTop: -28 }}
              className="w-10 h-2 bg-[#F7679F]"
            ></div>
            <p
              style={{ marginTop: -15, fontSize: "small" }}
              className="text-black"
            >
              tertiary
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <div
              style={{ marginTop: -28 }}
              className="w-10 h-2 bg-[#FB9FB6]"
            ></div>
            <p
              style={{ marginTop: -15, fontSize: "small" }}
              className="text-black"
            >
              residential
            </p>
          </div>
        </div>
      </div>

      {!delayedToggleState && <LiaRoadSolid />}
    </div>
  );
};

export default RoadStyleButton;
