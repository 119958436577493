import { BiArrowBack } from "react-icons/bi";
import { Button, Form, Modal, Placeholder, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "@Config/firebase";
import { useData } from "@Contexts/DataContext";
import { AiFillDelete } from "react-icons/ai";

export default function Categories() {
  const navigate = useNavigate();
  const { categories } = useData();

  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        padding: "0px 70px 0px 320px",
      }}
    >
      <div className="main-content">
        <h1 className="text-center mt-5">Categories</h1>
      </div>
      <div className="flex gap-2">
        <Button id="theme-button" onClick={() => navigate(-1)}>
          <BiArrowBack />
        </Button>
        <Button id="theme-button" onClick={() => setShow(true)}>
          Create
        </Button>
      </div>
      <Table striped bordered className="mt-4">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Color</th>
            <th scope="col">Options</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category: any, idx: number) => (
            <tr key={idx}>
              <th scope="row">{idx + 1}</th>
              <th>{category.name}</th>
              <th>
                <Placeholder
                  bg={category.color}
                  style={{
                    height: 35,
                    width: 35,
                    opacity: 1,
                    borderRadius: 5,
                    cursor: "default",
                  }}
                />
              </th>
              <th style={{ display: "flex", justifyItems: "center", gap: 8 }}>
                <Button
                  variant="danger"
                  onClick={() =>
                    deleteDoc(doc(db, `database-categories/${category.id}`))
                  }
                >
                  <AiFillDelete />
                </Button>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
      <CreateModal show={show} onHide={() => setShow(false)} />
    </div>
  );
}

const CreateModal = ({ show, onHide }: any) => {
  const [data, setData] = useState({ name: "", color: "" });

  const submit = async () => {
    await addDoc(collection(db, `database-categories`), data);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Create Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          placeholder="Name"
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <div className="mt-4">
          <Form.Label>Choose Color</Form.Label>
          <div className="flex gap-2">
            <Placeholder
              onClick={() => setData({ ...data, color: "primary" })}
              bg="primary"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "primary" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
            <Placeholder
              onClick={() => setData({ ...data, color: "secondary" })}
              bg="secondary"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "secondary" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
            <Placeholder
              onClick={() => setData({ ...data, color: "success" })}
              bg="success"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "success" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
            <Placeholder
              onClick={() => setData({ ...data, color: "danger" })}
              bg="danger"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "danger" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
            <Placeholder
              onClick={() => setData({ ...data, color: "warning" })}
              bg="warning"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "warning" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
            <Placeholder
              onClick={() => setData({ ...data, color: "info" })}
              bg="info"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "info" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
            <Placeholder
              onClick={() => setData({ ...data, color: "light" })}
              bg="light"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "light" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
            <Placeholder
              onClick={() => setData({ ...data, color: "dark" })}
              bg="dark"
              style={{
                height: 35,
                width: 35,
                opacity: 1,
                borderRadius: 5,
                cursor: "pointer",
                border: data.color === "dark" ? "3px #ff1d7f solid" : "",
              }}
            ></Placeholder>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onHide}>
          Close
        </Button>
        <Button
          id="theme-button"
          disabled={data.name === "" || data.color === ""}
          onClick={submit}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
