import { useState, useEffect, useRef } from "react";
import { Button, Modal, FloatingLabel, Form } from "react-bootstrap";
import { FaMapMarkerAlt, FaParking, FaFile } from "react-icons/fa";
import { AiFillShop, AiOutlineClose } from "react-icons/ai";
import { BsBuildings } from "react-icons/bs";
import { RxCorners, RxCornerTopRight } from "react-icons/rx";
import { useData } from "@Contexts/DataContext";
import { useAuth } from "@Contexts/AuthContext";
import CustomPolygon from "./CustomPolygon";
import { db } from "@Config/firebase";
import { collection, addDoc } from "firebase/firestore";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Image = ({ ...rest }: any) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div style={{ height: rest.height }} className="relative">
      {!loaded && <></>}
      <img
        style={{ opacity: loaded ? 1 : 0 }}
        onLoad={() => setLoaded(true)}
        {...rest}
      />
    </div>
  );
};

const saveProject = async (
  projectName: string,
  pipeline: string,
  userId: string,
  templateName: string,
  localeIds: string[],
  organizationId: string,
  isochroneCoordinates: any[]
) => {
  try {
    await addDoc(collection(db, `organizations/${organizationId}/projects`), {
      name: projectName,
      pipeline: pipeline,
      userId: userId,
      templateName: templateName,
      localeIds: localeIds,
      isochroneCoordinates: isochroneCoordinates,
      createdAt: new Date(),
    });
  } catch (error) {}
};

const LocaleButton = ({ mapRef, delayedToggleState, isochrone }: any) => {
  const [showDiv, setShowDiv] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocaleId, setSelectedLocaleId] = useState<string>("");
  const markersRef = useRef<google.maps.Marker[]>([]);
  const prevIsochroneRef = useRef<any>(null);

  const { locales } = useData();
  const { user } = useAuth();
  const isLocaleInIsochrone = (locale: any) => {
    const polygon = new CustomPolygon(
      isochrone.map((cords: any) => [cords[0], cords[1]])
    );
    return polygon.contains([
      locale.location.longitude,
      locale.location.latitude,
    ]);
  };

  const handleClick = (localeId: string) => {
    setSelectedLocaleId(localeId);
    setModalVisible(true);
  };

  const localesInIsochrone = locales.filter((locale: any) => {
    return (
      locale.visibility &&
      (locale.visibility.includes(user?.data?.default_organization) ||
        locale.visibility.length === 0) &&
      isLocaleInIsochrone(locale)
    );
  });

  useEffect(() => {
    if (mapRef.current) {
      if (
        JSON.stringify(prevIsochroneRef.current) !== JSON.stringify(isochrone)
      ) {
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];

        localesInIsochrone.forEach((locale: any) => {
          const marker = new google.maps.Marker({
            position: {
              lat: locale.location.latitude,
              lng: locale.location.longitude,
            },
            map: mapRef.current,
            icon: {
              size: new google.maps.Size(35, 35),
              url: locale.pin || "/shop.svg",
            },
          });
          markersRef.current.push(marker);
        });

        prevIsochroneRef.current = isochrone;
      }
    }
  }, [localesInIsochrone, mapRef, isochrone]);

  const handleButtonClick = () => {
    setIsTransforming(true);
    setShowDiv(true);
    setTimeout(() => setIsTransforming(false), 500);
  };

  const handleCloseClick = () => {
    setIsTransforming(true);
    setTimeout(() => {
      setShowDiv(false);
      setIsTransforming(false);
    }, 500);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: delayedToggleState ? "113px" : "60px",
          left: "261px",
          zIndex: 1000,
          transition: "all 0.5s ease",
          borderRadius: showDiv ? "10px" : "50%",
          width: showDiv ? "350px" : "45px",
          height: showDiv ? "calc(100% - 325px)" : "45px",
          display: "flex",
          flexDirection: showDiv ? "column" : "row",
          alignItems: showDiv ? "flex-start" : "center",
          justifyContent: showDiv ? "flex-start" : "center",
          padding: showDiv ? "10px" : "initial",
          backgroundColor: showDiv ? "white" : "initial",
          color: showDiv ? "black" : "white",
          overflowY: showDiv ? "auto" : "hidden",
          boxShadow: showDiv ? "0 4px 8px rgba(0,0,0,0.2)" : "none",
          border: showDiv ? "1px solid #ddd" : "none",
          transform: isTransforming
            ? showDiv
              ? "scale(1)"
              : "scale(0)"
            : "none",
        }}
      >
        {!showDiv && (
          <Button
            onClick={handleButtonClick}
            id="theme-button"
            style={{
              borderRadius: "50%",
              width: "45px",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              transition: "all 0.5s ease",
              transform: isTransforming ? "scale(0)" : "scale(1)",
            }}
            disabled={localesInIsochrone.length === 0}
          >
            {localesInIsochrone.length}
          </Button>
        )}
        {showDiv && (
          <div className="w-full h-full relative">
            <AiOutlineClose
              onClick={handleCloseClick}
              className="absolute right-1 cursor-pointer text-black text-lg z-[1100]"
            />

            <div className="w-full text-left">
              <h4 className="text-cprimary font-bold text-base">
                Available Locales
              </h4>
            </div>

            <hr
              className="my-2"
              style={{
                border: "1px rgb(255 29 127) solid",
              }}
            />
            <div className="flex flex-col gap-2 items-end w-full h-[calc(100%-50px)] overflow-y-auto">
              {localesInIsochrone.map((locale: any, idx: any) => (
                <div
                  key={idx}
                  className="relative rounded-xl border w-full p-2"
                >
                  <Image
                    src={locale.images[0]}
                    height={120}
                    className="w-full h-auto rounded min-w-[100%] drop-shadow-sm"
                  />
                  <div className="p-2 flex flex-col gap-1">
                    <h3 className="text-sm mb-1 font-bold mt-14 text-left">
                      {locale.location.address}
                    </h3>

                    <div className="flex gap-1 flex-wrap opacity-70 mt-1">
                      <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                        <RxCorners className="text-[10px]" />
                        <span className="text-[11px]">
                          {locale.sqMeter} m<sup>2</sup>
                        </span>
                      </h3>
                      <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                        <BsBuildings className="text-[10px]" />
                        <span className="text-[11px]">
                          {locale.floors} Floor(s)
                        </span>
                      </h3>

                      {locale.haveParking && (
                        <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                          <FaParking className="text-[10px]" />
                          <span className="text-[11px]">Parking</span>
                        </h3>
                      )}

                      {locale.isCorner && (
                        <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                          <RxCornerTopRight className="text-[10px]" />
                          <span className="text-[11px]">Corner</span>
                        </h3>
                      )}

                      {locale.commercial && (
                        <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                          <AiFillShop className="text-[10px]" />
                          <span className="text-[11px]">Commercial</span>
                        </h3>
                      )}
                    </div>
                    <div className="flex justify-between items-end h-full">
                      <h1 className="text-[16px] font-bold">
                        {USDollar.format(locale.price)}
                      </h1>
                      <div>
                        <Button
                          id="theme-button"
                          className="bg-yellow-400 text-white w-[40px] h-[40px] px-2 py-1 rounded-md"
                          onClick={() => handleClick(locale.id)}
                        >
                          <FaFile className="ml-1 text-[15px]" />
                        </Button>

                        <Button
                          id="theme-button"
                          className="bg-yellow-400 text-white w-[40px] h-[40px] px-2 py-1 rounded-md ml-2"
                          onClick={() =>
                            mapRef.current.setCenter({
                              lat: locale.location.latitude,
                              lng: locale.location.longitude,
                            })
                          }
                        >
                          <FaMapMarkerAlt className="ml-1 text-[15px]" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Project
        isochrone={isochrone}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedLocaleId={selectedLocaleId}
      />
    </>
  );
};

export default LocaleButton;

const Project: React.FC<{
  isochrone: any[];
  modalVisible: any;
  setModalVisible: any;
  selectedLocaleId: string; // Add this prop
}> = ({ isochrone, modalVisible, setModalVisible, selectedLocaleId }) => {
  const [projectName, setProjectName] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [pipelineContent, setPipelineContent] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [errors, setErrors] = useState<{
    projectName?: string;
    template?: string;
  }>({});
  const [selectedLocales, setSelectedLocales] = useState<Set<string>>(
    new Set()
  );
  const [loading, setLoading] = useState(false); // Add loading state
  const { user } = useAuth();
  const data = useData();
  const { templates, locales } = data;

  const userDefaultOrgId = user?.data?.default_organization;
  const organization = data.organizations?.find(
    (org) => org.id === userDefaultOrgId
  );

  const selectedLocale = locales.find(
    (locale) => locale.id === selectedLocaleId
  );
  useEffect(() => {
    if (modalVisible) {
      setProjectName("");
      setSelectedTemplateId("");
      setPipelineContent("");
      setTemplateName("");
      setErrors({});
      setSelectedLocales(new Set([selectedLocale.id]));
    }
  }, [modalVisible, selectedLocale]);

  useEffect(() => {
    const selectedTemplate = templates.find(
      (template) => template.id === selectedTemplateId
    );
    setPipelineContent(selectedTemplate?.pipeline || "");
    setTemplateName(selectedTemplate?.name || "");
  }, [selectedTemplateId, templates]);

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const templateId = e.target.value;
    setSelectedTemplateId(templateId);

    const selectedTemplate = templates.find(
      (template) => template.id === templateId
    );
    setTemplateName(selectedTemplate?.name || "");
  };

  const handleSaveProject = async () => {
    const newErrors: { projectName?: string; template?: string } = {};

    if (!projectName.trim()) {
      newErrors.projectName = "Project name is required.";
    }

    if (!selectedTemplateId) {
      newErrors.template = "Template selection is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (user && organization) {
      setLoading(true);
      try {
        const processedIsochrone = isochrone.map((coord: any) => ({
          latitude: coord[1],
          longitude: coord[0],
        }));

        await saveProject(
          projectName,
          pipelineContent,
          user.uid,
          templateName,
          Array.from(selectedLocales),
          organization.id,
          processedIsochrone
        );
        setModalVisible(false);
      } catch (error) {
        console.error("Error saving project:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("User is not authenticated or organization is not found");
    }
  };
  return (
    <>
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-3">
            <div className="mb-1">
              <FloatingLabel controlId="projectName" label="Project Name">
                <Form.Control
                  type="text"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  className="form-control-sm"
                />
              </FloatingLabel>
              {errors.projectName && (
                <div className="text-red-500 text-xs mt-1">
                  {errors.projectName}
                </div>
              )}
            </div>
            <div>
              <FloatingLabel
                controlId="templateSelect"
                label="Select Template"
                className="mt-[-13px] mb-[2px]"
              >
                <Form.Select
                  value={selectedTemplateId}
                  onChange={handleTemplateChange}
                  className="form-select-sm"
                  aria-label="Select template"
                >
                  <option value="" disabled hidden></option>
                  {templates.map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              {errors.template && (
                <div className="text-red-500 text-xs mt-1">
                  {errors.template}
                </div>
              )}
            </div>

            <div className="flex flex-row gap-2">
              {selectedLocale && (
                <div
                  key={selectedLocale.id}
                  className={`relative flex-none w-60 rounded-xl border p-2 bg-white shadow-sm transition-opacity duration-300 ${
                    selectedLocales.has(selectedLocale.id)
                      ? "opacity-100"
                      : "opacity-50"
                  }`}
                >
                  <Image
                    src={selectedLocale.images[0]}
                    height={80}
                    className="w-full h-auto rounded min-w-[100%] drop-shadow-sm"
                    alt="Locale Image"
                  />
                  <div className="p-1 flex flex-col gap-1">
                    <h3 className="text-xs font-bold mt-5 text-left">
                      {selectedLocale.location.address}
                    </h3>

                    <div
                      className="flex gap-1 flex-wrap opacity-70"
                      style={{ minHeight: "1.5em" }}
                    >
                      <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                        <RxCorners className="text-[10px]" />
                        <span className="text-[11px]">
                          {selectedLocale.sqMeter} m<sup>2</sup>
                        </span>
                      </h3>
                      <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                        <BsBuildings className="text-[10px]" />
                        <span className="text-[11px]">
                          {selectedLocale.floors} Floor(s)
                        </span>
                      </h3>

                      {selectedLocale.haveParking && (
                        <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                          <FaParking className="text-[10px]" />
                          <span className="text-[11px]">Parking</span>
                        </h3>
                      )}

                      {selectedLocale.isCorner && (
                        <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                          <RxCornerTopRight className="text-[10px]" />
                          <span className="text-[11px]">Corner</span>
                        </h3>
                      )}

                      {selectedLocale.commercial && (
                        <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                          <AiFillShop className="text-[10px]" />
                          <span className="text-[11px]">Commercial</span>
                        </h3>
                      )}
                    </div>
                    <div className="flex justify-between items-end h-full">
                      <h1 className="text-[16px] font-bold mt-2">
                        {USDollar.format(selectedLocale.price)}
                      </h1>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-between">
          <Button
            variant="secondary"
            onClick={() => setModalVisible(false)}
            className="btn-xs py-1 px-2 rounded text-black bg-transparent border-0"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            id="theme-button"
            onClick={handleSaveProject}
            disabled={loading}
          >
            {loading ? "Saving..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
